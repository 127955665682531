<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | SITE_NAME` : `SITE_NAME` }}</template>
  </metainfo>
  <div class="container">
    <TheHeader/>
      <router-view />
    <TheFooter/>
  </div>
</template>

<script>
import TheHeader from './components/TheHeader.vue';
import TheFooter from './components/TheFooter.vue';
import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
//import { useMeta } from 'vue-meta'

export default {
  setup() {
    const siteData = reactive({
      title: `Mendel Helps`,
      description: `c2dak5gv8v5q1o3jqjthy76ghha8sh`,
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `facebook-domain-verification`,
          content: computed(() => siteData.description),
        },
        ],
    })
  },
  name: 'App',
  components: {
    TheHeader,
    TheFooter
  },
  beforeCreate(){
    if (window.location.protocol == 'http:') {
      console.log("you are accessing us via "
        + "an insecure protocol (HTTP). "
        + "Redirecting you to HTTPS.");
      window.location.href =
        window.location.href.replace(
            'http:', 'https:');
    }
    else if (window.location.protocol == "https:") {
        console.log("you are accessing us via"
          + " our secure HTTPS protocol.");
      }
  }
}
</script>

<style>
#app {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: 0;
  padding: 0;
}
.container {
  margin: 0;
  padding: 0;
}
</style>
