<template>
    <div class="footer-container">
        <div class="logo-menu">
            <div class="logo-container"><router-link to="/"><img :src="logo" class="logo"/></router-link></div>
            <div class="menu">
                <router-link to='/contact'><p>Kontakt</p></router-link>
                <router-link to='/privatePolicy'><p>Polityka prywatności</p></router-link>
                <router-link to='/statute'><p>Regulamin</p></router-link>
            </div>
            </div>
            <div class="socialMedia-icon-box">
                <router-link to='#'><img :src="facebookIcon" class="socialMedia-icon"/></router-link>
                <router-link to='/main'><img :src="instagramIcon" class="socialMedia-icon"/></router-link>
            </div>
        </div>
</template>

<script>
import logo from "../photos/Mendel_Helps_maly_sygnet.png";
import facebook from "../photos/facebook_logo.png";
import instagram from "../photos/ig_logo.png";
export default {
    data() {
        return {
            logo: logo,
            facebookIcon: facebook,
            instagramIcon: instagram
        }
    }
}
</script>

<style scoped>
.footer-container {
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(to right, #5D4DB7 , #3084E2);
    margin:0;
    border-radius: 30px 30px 0 0;
    overflow: hidden;
    z-index: 100;
}
.logo-menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background:none;
}
.menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #6d8b33;
    font-size: 17px;
    margin-right: 50px;
    margin-left: 50px;
}
.menu div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.menu p {
    color: white;
    margin: 15px;
    transition: 0.3s ease-out;
}
.menu p:hover {
    transform: scale(1.1);
}
.socialMedia-icon-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    float: right;
    align-items: center;
    margin-right: 30px;
}
.socialMedia-icon {
    width: 35px;
    margin: 5px 10px;
}
a {
    text-decoration: none;
    background-color: rgb(255, 255, 255, 0.0);
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
}
.logo-container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.logo {
    width: 70px;
    margin-left: 50px;
    margin-bottom: 0;
    align-self: center;
}
@media only screen and (max-width: 600px) {
.menu {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    margin-right: 0;
    margin-left: 20px;
    align-self: center;
}
.menu div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.menu p {
    color: white;
    margin: 0px;
    font-size: 14px;
    margin: 5px;
    transition: 0.3s ease-out;
}
.socialMedia-icon-box {
    width: 33.3%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-right: 0;
    padding-right: 20px;
}
.socialMedia-icon {
    width: 35px;
    margin: 5px 10px;
}
a {
    text-decoration: none;
}
.logo-menu {
    width: 66.6%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background:none;
}
.logo {
    width: 60px;
    margin-left: 20px;
}
}
</style>