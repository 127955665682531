<template>
<div class="policy-container">
    <div>
        <h3>REGULAMIN PORTALU MENDEL HELPS LIFE</h3>

<p class="center">§1</p>
<p class="center">Postanowienia ogólne.</p>

<p>1. Definicje.</p>
<p>Ilekroć w dokumencie jest mowa o:</p>
<p>a/ Regulaminie – rozumie się przez to niniejszy dokument o nazwie „REGULAMIN PORTALU MENDEL HELPS LIFE”;</p>
<p>b/ Portalu – rozumie się przez to serwis internetowy „Portal Mendel Helps Life”, platformę oraz powiązane z nimi media i/lub konta w mediach społecznościowych zarządzane i/lub prowadzone przez Administratora, na których mogą być umieszczane całościowe i/lub częściowi dane z Wizytówek Partnerów;</p>
<p>c/ Wizytówce – elektroniczny zapis zawierający dane Partnera (logo, zdjęcie, krótką informację o przedmiocie działalności, dane teleadresowe i kontakt), przekazane Administratorowi dobrowolnie, poprzez wypełnienie tabeli w zakładce Zostań naszym Partnerem umieszczonej na Portalu, przeznaczone wyłącznie do zapoznania się z nimi i nawiązania kontaktu przez Użytkowników przedmiotowego Portalu w celu zakupu Produktu oferowanego przez Partnera na Platformie i/lub mediach i kontach w mediach społecznościowych powiązanych i prowadzanych przez Administratora;</p>
<p>d/ Partnerze – podmiot, który został zarejestrowany poprzez stworzenie Wizytówki na Portalu i tym samym jest producentem Produktu ( produktów rolno-spożywczych, kosmetycznych i/lub świadczącym usługi, takie jak usługi HORECA, usługi rehabilitacyjne, lecznicze, uzdrowiskowe, sanatoryjne, z zakresu działalności fitness, wellness, spa itp. ) z zastrzeżeniem świadczenia i/lub posiadania i/lub utrzymania i/lub wytwarzania ponadprzeciętnej jakości  Produktu, co Partner pozostawia w zakresie samodzielnej, autonomicznej i  jednostronnej decyzji Administratora tym samym pozostawiając temuż ostateczną decyzję co do możliwości rejestracji Partnera na Portalu i tym samym korzystania z potencjału oferowanych usług przez Portal i powiązanych z nim oraz zarządzanych przez Administratora mediów i/lub kont w mediach społecznościowych;</p>
<p>e/ Administratorze – właściciel Portalu Mendel Helps Life oraz wszelkich usług oferowanych i powiązanych z przedmiotowym Portalem oraz mediów i kont w mediach społecznościowych zarządzanych przez Administratora;</p>
<p>f/ Koordynatorze – osobie, osobach upoważnionych przez Administratora do kontaktu z partnerami i użytkownikami portalu oraz zarządzaniem treściami na Portalu, a także na innych powiązanych i zarządzanych przez Administratora mediach i/lub kontach w mediach społecznościowych;</p>
<p>g/ Użytkowniku – osobę fizyczną i/lub podmiot przeglądający i/lub zapoznającą się i/lub korzystający zgodnie z zapisami niniejszego Regulaminu z treści i/lub informacji zamieszczonymi na Portalu i powiązanymi z nim mediami i kontami w mediach społecznościowych w celu informacyjnym i/lub zapoznawczym i/lub nawiązania kontaktu celem zakupu oferowanych przez Partnera i/lub Portal Produktów;</p>
<p>i/ Produkcie – rozumie się przez to wytwarzany przez Partnera produkt i/lub świadczenie przez Partnera usługi z zakresu wytwarzania produktów rolno-spożywczych, kosmetycznych oraz świadczenia usług HORECA, usług rehabilitacyjnych, leczniczych, uzdrowiskowych, sanatoryjnych, z zakresu działalności fitness, wellness, spa itp., z zastrzeżeniem ich ponadprzeciętnej jakości potwierdzonej posiadanymi certyfikatami, audytami, sposobami wytwarzania itp.., tym samym będąc świadectwem jakości i stylu prowadzenia działalności, szacunku dla otoczenia, przyrody i drugiego człowieka.</p>
<p>j/ Wniosku – czyli znajdującej się w zakładce Zostań naszym Partnerem na Portalu tabeli z danymi firmowymi, teleadresowymi itp. oraz niezbędnymi informacjami handlowymi,  służącym podmiotom do wnioskowania do Administratora o utworzenie Wizytówki i tym samym stania się Partnerem Portalu</p>
<p>k/ Cenniku – dokumencie będącym integralną częścią Regulaminu, określającym finansowe zasady współpracy Partnera z Administratorem.</p>
    <p>2. Niniejszy Regulamin określa zakres, warunki i zasady korzystania przez:</p>
<p>a/Partnerów z usług świadczonych przez Portal i stanowi formę umowy, co oznacza, że zaakceptowanie zapisów Regulaminu wraz z załącznikami przez Partnera oznacza jego dobrowolną i świadomą decyzję jaką jest zawarcie umowy pomiędzy Partnerem i Portalem w ramach zaproszenia do współpracy skierowanego przez Portal do Partnera i/lub w ramach akcesu zgłaszanego przez Partnera do współpracy z Portalem i/lub mediami i kontami w mediach społecznościowych prowadzonych przez Administratora;</p>
<p>b/Użytkowników z informacji, usług i ofert świadczonych przez Portal;</p>
<p>c/ Partner, Użytkownik oraz każdy inny podmiot i/lub osoba fizyczna mająca dostęp do Portalu, powiązanych z nim mediów i kont w mediach społecznościowych, zobowiązana jest do powstrzymania się od wykorzystywania w celach zarobkowych, marketingowych, handlowych, czy w jakichkolwiek innych celach informacji, treści, baz danych zamieszczonych na tychże, bez pisemnej zgody Administratora i/lub osoby przez niego upoważnionej. Należy przy tym zaznaczyć, że wymieniony zakres ewentualnego użytkowania informacji i treści nie ma charakteru wyczerpującego.</p>
   <p> 3. Ideą jaka przyświeca Portalowi i zarządzanym przez właściciela Portalu innym mediom i kontom w mediach społecznościowych, jest promocja szeroko rozumianego zdrowego stylu życia, promocja producentów i usługodawców oferujących kupującym ponadprzeciętną jakość swoich produktów i usług, ponadprzeciętną jakość  i styl prowadzenia działalności oraz  poszanowanie wartości chrześcijańskich w trakcie procesu wytwarzania, dystrybucji swoich produktów, jak i świadczenia usług, na czele z szeroko rozumianym szacunkiem do drugiego człowieka. Niezaprzeczalną wartością dodaną Portalu jest umożliwienie bezpośredniego kontaktu Użytkownika z Partnerem i tym samym umożliwienie dokonania zakupu Produktów bezpośrednio u Partnera, bez udziału osób trzecich i tym samym budowanie wzajemnych relacji międzyludzkich.</p>
<p class="center">§2</p>
<p class="center">Warunki rejestracji i korzystania z Portalu.</p>
    <p>1. Korzystanie z Portalu przez Partnerów i Użytkowników jest dobrowolne i podlega regulacjom zawartym w niniejszym Regulaminie.</p>
    <p>2. Podmiot przed przystąpieniem do rozmów z Administratorem dotyczących współpracy w zakresie uczestniczenia w projekcie Portalu obowiązuje się do przestrzegania zasad poufności dotyczących idei i planów rozwoju Portalu Mendel Helps Life i marki Mendel Helps oraz zasad współpracy w obszarze nie podanym i nie udostępnianym do powszechnej wiadomości przez właściciela Portalu Mendel Helps Life i marki Mendel Helps</p>
    <p>3. Podmiot może ubiegać się o status Partnera i tym samym Jego dane mogą zostać umieszczane na Portalu w przypadku:</p>
<p>a/wytwarzania i/lub oferowania Produktu o ponadprzeciętnej jakości potwierdzonej posiadanymi certyfikatami, audytami, sposobami wytwarzania itp.,</p>
<p>b/zaproszenia do współpracy wystosowanym do potencjalnego Partnera przez Administratora i/lub Koordynatora Portalu i akceptacji tegoż zaproszenia przez potencjalnego Partnera;</p>
<p>c/wystąpienia z Wnioskiem o umieszczenie na Portalu przez podmiot i spełnieniu wymagań stawianych Partnerom przez Administratora oraz po uzyskaniu akceptacji Administratora i/lub Koordynatora;</p>
<p>d/terminowego uregulowania przez podmiot aspirujący do roli Partnera kwot zawartych na fakturze/fakturach wystawionych przez Administratora na rzecz podmiotu aspirującego, a wynikających z Cennika, co stwierdza Administrator po zaksięgowaniu wpływów na rachunku bankowym Administratora;</p>
   <p> 4. Wymagania, o których mowa w §2 punkt 2a, są następujące dla Produktów określanych mianem rolno-spożywczych:</p>
             <p> a/ muszą należeć do następujących grup asortymentowych:</p>
               <p> 1/ dżemy, powidła, konfitury;</p>
               <p> 2/ mąki, kasze, produkty mączne;</p>
                <p>3/ mięso, wędliny, przetwory mięsne;</p>
                <p>4/ miody i produkty pszczelarskie;</p>
                <p>5/ nabiał;</p>
                <p>6/ napoje alkoholowe;</p>
                <p>7/ octy;</p>
                <p>8/ oleje;</p>
                <p>9/ przetwory owocowe- warzywne, marynaty;</p>
              <p>10/ ryby i przetwory rybne;</p>
              <p>11/ soki;</p>
              <p>12/ sosy, musztardy, przyprawy;</p>
              <p>13/ susze;</p>
              <p>14/ syropy;</p>
              <p>15/ warzywa-owoce;</p>
              <p>16/ zioła, herbaty;</p>
             <p>17/ wyroby mieszane na bazie wyżej wymienionych;</p>
             <p>18/ inne.</p>
<p>b/ muszą spełniać następujące warunki w zakresie produkcji, składu surowcowego, cech fizyko-chemicznych, mikrobiologicznych, sensorycznych, zawartości składników odżywczych, stosowania substancji dodatkowych, metod przetwarzania i utrwalania lub właściwości organoleptycznych:</p>
<p>1/być produktem tradycyjnym i/lub</p>
<p>2/być produktem naturalnym i/lub</p>
<p>3/być produktem ekologicznym i/lub</p>
<p>4/być produktem regionalnym</p>

    <p>5. Wymagania, o których mowa w §2 punkt 2a, są następujące dla Produktów określanych mianem kosmetycznych:</p>
<p>a/być „każdą substancję lub mieszaninę przeznaczoną do kontaktu z zewnętrznymi częściami ciała ludzkiego (naskórkiem, owłosieniem, paznokciami, wargami oraz zewnętrznymi narządami płciowymi) lub z zębami oraz błonami śluzowymi jamy ustnej, którego wyłącznym lub głównym celem jest utrzymywanie ich w czystości, perfumowanie, zmiana ich wyglądu, ochrona, utrzymywanie w dobrej kondycji lub korygowanie zapachu ciała”</p>
<p>b/ należeć do następujących grup produktowych</p>
<p>1/ środki czyszczące</p>
<p>2/ środki pielęgnujące i ochronne</p>
<p>3/ środki zapachowe</p>
<p>4/ środki do zmiany wyglądu</p>
              <p>c/ powinien być produktem</p>
              <p>1/ którego składniki pozyskiwane są wyłącznie z ekologicznych i certyfikowanych upraw i
                   plantacji</p>
              <p>2/ akceptowalne są składniki pochodzenia zwierzęcego, pozyskiwane z żywych zwierząt np.
                   mleko, lanolina, wosk pszczeli</p>
              <p>3/ zawierać powinny wyłącznie naturalne barwniki i nie mogą być dodatkowo perfumowane</p>
              <p>4/ zgodnie z wykazem Komitecie Zdrowia Publicznego Rady Europy (ESCOP) podczas
                   produkcji tych kosmetyków zakazane są następujące surowce: parabeny, silikony,
                   karbomery oraz konserwanty nieidentyczne z naturalnymi, pochodne ropy naftowej
                   (wazelina, parafina, olej mineralny) oraz aromaty i barwniki syntetyczne</p>
             <p>5/ opakowane powinne być w opakowania pozbawione dodatkowych kartoników
                  wewnętrznych powiększających ich objętość, folii zewnętrznej, a same opakowania
                  powinny pochodzić z surowców wtórnych i ulegać biodegradacji</p>

    <p>6. Wymagania, o których mowa w §2 punkt 2a, są następujące dla Produktów określanym mianem:</p>
<p>a/ być usługami materialnymi i/lub</p>
<p>b/ być usługami niematerialnymi</p>
<p>c/ być usługami o ponadprzeciętnej jakości jako różnicy pomiędzy usługą oczekiwaną przez klienta – jakością oczekiwaną, a usługą otrzymaną – jakością nabywaną, mającą swoje źródło w oczekiwaniach i wyobrażeniach klienta potencjalnej usługi przed jej wykonaniem w zderzeniu z odczuciami po usłudze wykonanej opierając się na oceny kryteriach z metody SERVQUAL</p>
    <p>7. Podmiot i Partner mogą się starać o przyznanie Certificate Mendel Helps i tym samym korzystać z dodatkowych bonusów przyznanych przez Administratora. Zasady przyznania przedmiotowego certyfikatu określa Regulamin Certyfikatu Mendel Helps będący dostępny dla wnioskujących po akceptacji ich kandydatury przez Kapitułę Certyfikatu.</p>
    <p>8. Przyjęcie zaproszenia i/lub wystąpienie z Wnioskiem podmiotu o przyznanie statusu Partnera na Portalu jest równoznaczne z:</p>
<p>a/akceptacją treści, zapisów, wymogów, zgód przedmiotowego Regulaminu przez podmiot zaproszony i/lub wnioskujący i jest równoznaczne ze złożeniem przez podmiot oświadczenia następującej treści:</p>
<p>„Dobrowolnie przystępuję do współpracy z Portalem, zapoznałem się z treścią Regulaminu i akceptuję jego zapisy. Wszelkie dane przekazane w drodze rejestracji Portalowi celem umieszczenia na Wizytówce są zgodne z prawdą, biorę za nie całkowitą odpowiedzialność i poddaję się dobrowolnie procesowi weryfikacji podanych przeze mnie danych przez Administratora”</p>
<p>b/przyjęciem, zaakceptowaniem i zobowiązaniem się do terminowego uregulowania wystawionych, a mających swe źródło w Cenniku, faktur przez Administratora. Przy czym, każde wystawienie faktury jest poprzedzone terminowym uregulowaniu kwoty widniejącej na wystawionej na rzecz podmiotu faktury Pro forma i następuje niezwłocznie po zaksięgowaniu środków widniejących na fakturze Pro forma przez dział księgowości Administratora.</p>
    <p>9. Podmiot staje się Partnerem Portalu po spełnieniu zapisów łącznie wszystkich Punktów Regulaminu i dodatkowo każdorazowo autonomicznej decyzji Administratora i/lub Koordynatora.</p>
    <p>10. Partner pomimo audytu dokonanych przez Administratora przekazanych danych jest  wyłącznie odpowiedzialny za ich treść oraz wszelkie stwierdzone w nich przypadki naruszenia praw osób trzecich i tym samym jest jedynym adresatem kierowanych pod adresem Administratora z tego tytułu jakichkolwiek roszczeń podmiotów i osób trzecich, zwalniając  tym samym Administratora i osoby z nim współpracujące z odpowiedzialności, w tym prawnej i jakichkolwiek roszczeń wynikających z przypadków naruszeń praw osób trzecich .</p>
    <p>11. Partner przyjmując zaproszenie i/lub występując z Wnioskiem o współpracę do Administratora Portalu po utworzeniu Wizytówki na Portalu wyraża zgodę, biorąc pod uwagę zapisy Ustaw i przepisów prawa z zakresu Ochrony praw autorskich, na wykorzystywanie przez Administratora przekazanych materiałów, w tym również materiałów z zakresu identyfikacji wizualnej, do wszelkich działań promujących ideę Portalu tak na samym Portalu, jak i na  powiązanych z nimi mediach i/lub kontach w mediach społecznościowych i/lub innych miejscach w przestrzeni wybranej przez Administratora i służącej promocji idei, jak i samego Portalu.</p>
    <p>12. Partner każdorazowa ma prawo do wnioskowania o weryfikację, aktualizację, modyfikację i zmianę przekazanych przez siebie danych i tym samym ich zmian na Wizytówce, a Administrator i/lub Koordynator po sprawdzeniu ich zasadności jest zobowiązanych do podjęcia niezwłocznych działań zmierzających do skorygowania danych i zmiany zapisów w Wizytówce.</p>
    <p>13. Partner jest w pełni odpowiedzialny i tym samym bierze na siebie całą odpowiedzialność za oferowane przez siebie Produkty i zgodność z podanymi przez Partnera Administratorowi informacjami także z tego zakresu, w tym za skład Produktów, ich jakość, posiadanie przez Partnera wymaganych prawem zezwoleń, pozwoleń, certyfikatów, produkowanie i świadczenie przez Partnera usług zgodnie z przypisanymi do charakteru prowadzonej przez Partnera działalności oraz obowiązującymi normami i przepisami prawa.</p>
    <p>14. Partner jest zobowiązany z należytą starannością i dbałością o zadowolenie Użytkownika pod sankcją usunięcia z Portalu, realizować proces zamówienia Produktu przez Użytkownika Portalu, mając świadomość idei przyświecającej Portalowi zawartej w Regulaminie.</p>
    <p>15. Partner będąc odpowiedzialny za oferowane przez siebie Produkty, zgodnie z zapisami punktu 13 niniejszego Paragrafu, przyjmuje na siebie pełną odpowiedzialność z tytułu dokonanej transakcji z Użytkownikiem, tak w obszarze jakości, ceny jak i terminu i sposobu realizacji zamówienia Produktu dokonanego przez Użytkownika u Partnera Portalu. Administrator nie jest stroną w jakichkolwiek sporach wynikających z nieprawidłowego przebiegu transakcji na linii Partner – Użytkownik i tym samym wszelkie skutki odszkodowawcze mogące z tego tytułu pojawić się występować mogą tylko po stronach zawartej umowy transakcji, tj. po stronie Partnera i Użytkownika, co W/w przyjmują do wiadomości i w pełni akceptują.</p>
    <p>16. Zadowolenie Użytkownika jest bardzo ważnym elementem działalności Platformy, tym samym Użytkownik jest proszony przez Administratora celem zapewnienia optymalnej obsługi i maksymalizacji zadowolenia Użytkownika o przekazywanie w formie pisemnej na wskazany na Portalu adres mailowy, czy za pośrednictwem skrzynki kontaktowej wszelkich informacji dotyczących nieprawidłowości występujących zdaniem Użytkownika na każdym etapie przebiegu procesu zakupu Produktu u Partnera Portalu. Informacje te posłużyć mogą Administratorowi do weryfikacji podanych w trakcie wnioskowania przez Partnera informacji i ostatecznej całościowej oceny Partnera Portalu, a w konsekwencji mogą stać ich się podstawą do podjęcia przez Administratora działań opisanych w Regulaminie względem Partnera Portalu.</p>
    <p>17. Partner ma obowiązek pod sankcją natychmiastowego usunięcia z Portalu – o czym decyduje autonomicznie każdorazowo Administrator – o niezwłocznym, nie przekraczającym 14 dni od daty powzięcia przez siebie informacji, poinformowaniu Administratora o wszelkich zmianach w swojej sytuacji, mających wpływ na możliwość i jakość oferowanego Produktu Użytkownikowi, w tym zawieszeniu działalności, likwidacji działalności, zmiany stosunku własnościowego, zmiany lub pogorszenia jakości oferowanego Produktu, odebraniu certyfikatów potwierdzających jakość Produktu itp.. Należy przy tym zaznaczyć, że wymieniona w przedmiotowym punkcie lista nie ma charakteru wyczerpującego.</p>
    <p>18. Wszelkie zmiany w sytuacji Partnera mające wpływ na możliwości i jakość oferowanych przez tegoż Produktu, a tym samym wywołujące w efekcie końcowym usunięcie Wizytówki przez Administratora z Portalu nie rodzi jakichkolwiek skutków odszkodowawczych na rzecz Partnera po stronie Administratora.</p>
    <p>19. Administrator ma prawo do usunięcia Partnera z bazy Portalu, a tym samym Wizytówki oraz wszelkich innych danych Partnera z Portalu oraz zarządzanych przez Administratora mediów i kont w mediach społecznościowych w następujących przypadkach:</p>
<p>a/ podania i/lub potwierdzenia i/lub braku zaprzeczenia niezgodnych ze stanem faktycznych danych, na których podstawie Administrator podjął decyzję o podjęciu współpracy z podmiotem i umieszczeniu wizytówki Partnera na Portalu;</p>
<p>b/ braku odpowiedzi w ustalonym terminie na pismo skierowane przez Administratora do Partnera, dotyczące zmian w sytuacji Partnera mogących mieć wpływ na możliwość i jakość oferowanych przez Partnera Produktów, o czym mowa w §2 pkt 12;</p>
<p>c/ braku realizacji zapisów §2 pkt 8;</p>
<p>d/ stwierdzonej przez Administratora pogarszającej się jakości oferowanych przez Partnera Produktów;</p>
<p>e/ naruszanie przez Partnera dóbr osób trzecich, dobrych obyczajów, wytwarzanie i/lub wprowadzenia do obrotu produktów i/lub usług, które naruszają zasady wartości chrześcijańskich, ze szczególnym uwzględnieniem szeroko rozumianego szacunku dla drugiego człowieka;</p>
<p>f/ posługiwanie się w działalności Partnera metodami, które w powszechnym odbiorze społecznym są uznawane jako zachowania nieetyczne, aspołeczne, wulgarne, obraźliwe itp.;</p>
<p>g/ Nieprzestrzegania przez Partnera innych, a nie wymienionych w tym punkcie zapisów Regulaminu</p>
<p>h/ z powodów w tym punkcie nie wymienionych, które w sposób oczywisty są sprzeczne z ideą Platformy.</p>
<p>i/ o fakcie wykreślenia z listy Administrator informuje Partnera drogą mailową na wskazany przez Partnera we Wniosku korespondencyjny adres mailowy.</p>
<p>j/ usunięcie Wizytówki z powodów wymienionych w niniejszym punkcie nie rodzi jakichkolwiek skutków odszkodowawczych po stronie Administratora, przy czym Administrator zastrzega sobie takie prawo wobec Partnera w przypadku naruszeń zapisów Regulaminu przez Partnera oraz zwrotu poniesionych opłat przez Partnera na rzecz Portalu.</p>
    <p>20. Partner ma prawo do rezygnacji bycia Partnerem Portalu w dowolnym momencie trwania umowy na podstawie informacji mailowej przesłanej przez Partnera na wskazany na Portalu adres mailowy Administratora. Rezygnacja Partnera nie pociąga za sobą konieczności zwrotu opłat poniesionych przez Partnera na rzecz Portalu, ale zobowiązuje Administratora do niezwłocznego usunięcia Wizytówki i innych informacji dotyczących Partnera z Portalu, mediów i kont w mediach społecznościowych zarządzanych przez Administratora.</p>
    <p>21. Administrator ma prawo odmówić współpracy i tym samym bycia Partnerem podmiotowi konkurencyjnemu, co jest wyłączną i autonomiczną decyzją Administratora i nie wymaga ze strony Administratora podawania przyczyn i wyjaśnień wnioskującemu podmiotowi.</p>
    <p>22. Administrator i/lub Koordynator i/lub osoby upoważnione przez Administratora zastrzegają sobie prawo do zmiany i/lub usuwania i/lub odrzucenia treści przekazanych przez podmiot i/lub Partnera w przypadku niezgodności tychże z ideą Portalu i jego Regulaminem, naruszają ustalone zasady współpracy, naruszają prawa i dobra osobiste podmiotów i osób trzecich, mogą być źródłem uzasadnionych żądań osób trzecich kierowanych pod adresem Administratora, zawierają treści obraźliwe, wulgarne, niezgodne z powszechnie uznanymi faktami, wymagają korekty językowej pod kątem przestrzegania zasad poprawnej polszczyzny, nie spełniając wymogów technicznych przekazanych przez Administratora itp..</p>
    <p>23. Podmiot i Partner na każdym etapie swojej działalności nie ma prawa posługiwać się pod rygorem skutków odszkodowawczych informacjami i/lub treściami i/lub oznaczeniami i/lub elementami graficznymi i/lub wizualnymi będącymi własnością i/lub sugerującymi związek pomiędzy Parterem i Administratorem bez pisemnej zgody Administratora.</p>
    <p>24. Ponadto Partner, mając na uwadze zapisy punktu 19 niniejszego paragrafu, zobowiązuje się do natychmiastowego, tj.  w terminie 7 dni od otrzymania na wskazany przez siebie we Wniosku adres mailowy wezwania do usunięcia wystawionego przez Administratora, usunięcia ze swoich materiałów, przestrzeni publicznej zarządzanej przez Partnera (strona internetowa, konta w mediach społecznościowych itp.) wszelkich o których mowa w punkcie 19 niniejszego paragrafu.</p>
    <p>25. Brak nieprzestrzegania zapisów punktów 19 i 20 §2 rodzi po stronie podmiotu i Partnera skutki odszkodowawcze na rzecz Administratora w wysokości 50.000 złotych netto (słowem: pięćdziesiąt tysięcy złotych) za każdy stwierdzony przypadek naruszenia.</p>

<p class="center">§3</p>
<p class="center">Ochrona Danych Osobowych.</p>
<p>1. Wszelkie szczegółowe zasady dotyczące przetwarzania danych osobowych, w tym w szczególności cele i podstawy prawne przetwarzania danych osobowych zostały zawarte w Polityce prywatności, której treść jest dostępna na Portalu.</p>

<p class="center">§ 4</p>
<p class="center">Postanowienia końcowe</p>
    <p>1. Administrator zastrzega sobie prawo do wprowadzania zmian w zapisach Regulaminu każdorazowo mających na celu realizację idei Portalu, o czym Administrator obowiązuje się poinformować na swoim Portalu. Partnerzy mogą w terminie 14 dni od ukazania się na Portalu przedmiotowych zmian przesłać pisemną informację na adres mailowy wskazany do korespondencji o braku akceptacji zmian i tym samym stosuje się zapisy Regulaminu dotyczące rozwiązania umowy.</p>
    <p>2. Administrator zastrzega sobie prawo do wprowadzenia ograniczeń w korzystaniu z Portalu z powodu koniecznych prac serwisowych, prac służących polepszeniu jego funkcjonalności, czy wreszcie prac związanych z usuwaniem awarii Portalu i/lub jego części lub elementów funkcjonalności niezbędnych do jego działania.</p>
    <p>3. Administrator nie ponosi odpowiedzialności za brak funkcjonowania Portalu z przyczyn nie leżących po stronie Administratora.</p>
    <p>4. Wszelkie spory wynikłe pomiędzy Administratorem z jednej strony i Partnerem i/lub Użytkownikiem strony zobowiązują się rozstrzygać w drodze polubownego rozwiązania sporu. W przypadku braku możliwości polubownego rozwiązania sporu, sądem właściwym miejscowo do rozpatrywania przedmiotowego sporu jest Sąd właściwy miejscowo dla siedziby Administratora.</p>
    <p>5. Regulamin wchodzi w życie z dniem opublikowania na Portalu.</p>
    </div>
    <div>
    <p class="center-bold">Cennik</p>
    <p>Niniejszy Cennik jest integralną częścią Regulaminu Portalu Mendel Helps Life i określa warunki finansowe uczestnictwa w przedmiotowym projekcie.</p>
    <p class="center">§1</p>
    <p>1. Umieszczenia danych podmiotu we wskazanym przez podmiot województwie, pod wskazanym adresem, wskazanej przez W/w kategorii i podkategorii wraz z podanym kontaktem telefonicznym w bazie danych Portalu.</p>
    <p>2. Umieszczenie danych podmiotu na Portalu, o którym mowa w punkcie 1 niniejszego paragrafu,
niezależnie od ilości wskazanych kategorii i podkategorii, pociąga za sobą konieczność poniesienia dla
każdego z osobna segmentu, tj. Produkty lub Wypoczynek lub Podreperuj Zdrowie oraz dla każdej
 lokalizacji ( m.in. z uwagi na możliwość wystąpienia różnic w jakości wytwarzanych produktów
 i/lub oferowanych usług w różnych lokalizacjach), następujących
okresowych, cyklicznych opłat w okresach 6 lub 12 miesięcznych przez podmiot wnioskujący w
zależności od wybrania jednej z poniższych opcji: </p>
    <p>a/15,87 złotych brutto miesięcznie przy założeniu opłaty z góry łącznie za okres 12 m-cy</p>
    <p>lub</p>
    <p>b/29,87 złotych brutto miesięcznie przy założeniu opłaty z góry łącznie za okres 6 m-cy</p>
    <p>3. Podmiot tym samym winny jest, patrząc na zapisy punktu 2 niniejszego paragrafu, uregulować przelewem na rachunek bankowy wskazany na fakturze  pro forma wystawionej przez Administratora na rzecz Partnera następujące kwoty</p>
    <p>a/ 190,44 złotych brutto to kwota wynikająca z wyboru opcji 2a niniejszego paragrafu, liczonej jako iloczyn kwot 15,87 x 12;</p>
    <p>b/ 179,82  złotych brutto to kwota wynikająca z wyboru opcji 2b niniejszego paragrafu liczonej jako iloczyn kwot 29,87 x 6; </p>
    <p class="center">§2</p>
    <p>1. Podmiot może zdecydować się na wybór dodatkowego wyróżnienia, a mianowicie:</p>
    <p>a/ pogrubienie czcionki i dodanie linka do adresu strony internetowej umożliwiającej podmiotowi dodatkowe wyróżnienie pośród konkurencji oraz szybki kontakt Użytkownika</p>
    <p>b/ wstawienie logotypu (sygnetu) lub grafiki, zdjęcia reklamującego działalność podmiotu wielkości sygnetu utrwalającej świadomość marki podmiotu </p>
    <p>c/ umieszczenie danych Partnera na pierwszych 30 pozycjach listy w danej podkategorii umożliwiającej większą szansę na wybór oferty Partnera przez Użytkownika Portalu</p>
    <p>2. Pogrubienie czcionki i dodanie adresu strony internetowej, o czym mowa w punkcie 1a niniejszego paragrafu pociąga za sobą konieczność poniesienia dodatkowych, okresowych i cyklicznych opłat w okresach 6 lub 12 miesięcznych przez podmiot wnioskujący w zależności od wybrania jednej z poniższych opcji:</p>
    <p> a/ 1,87 złotych brutto miesięcznie przy założeniu opłaty z góry łącznie za okres 12 m-cy, które każdorazowo należy dodać do kwoty zapisanej w niniejszym Cenniku w Paragrafie 1
    Punkt 2a, tj. do kwoty 15,87 złotych brutto</p>
    <p>b/3,87 złotych brutto miesięcznie przy założeniu opłaty z góry łącznie za okres 6 m-cy, które każdorazowo należy dodać do kwoty zapisanej w niniejszym Cenniku w Paragrafie 1
    Punkt 2b, tj. do kwoty 29,87 złotych brutto</p>
    <p>3. Wstawienie logotypu (sygnetu) lub grafiki, zdjęcia reklamującego działalność podmiotu wielkości sygnetu, o czym mowa w punkcie 1b niniejszego paragrafu pociąga za sobą konieczność poniesienia dodatkowych, okresowych i cyklicznych opłat 6 lub 12 miesięcznych przez podmiot wnioskujący w zależności od wybrania jednej z poniższych opcji:</p>
    <p>a/ 3,87 złotych brutto miesięcznie przy założeniu opłaty z góry łącznie za okres 12 m- cy,    które każdorazowo należy dodać do kwoty zapisanej w niniejszym Cenniku w Paragrafie 1 Punkt 2a, tj. do kwoty 15,87 złotych brutto</p>
    <p>b/ 5,87 złotych brutto miesięcznie przy założeniu opłaty z góry łącznie za okres 6 m-cy,które każdorazowo należy dodać do kwoty zapisanej w niniejszym Cenniku w Paragrafie 1
    Punkt 2b, tj. do kwoty 29,87 złotych brutto</p>
    <p>4. Umieszczenie danych podmiotu na pierwszych 30 pozycjach listy w danej podkategorii, o czym mowa w punkcie 1c, niniejszego paragrafu pociąga za sobą konieczność poniesienia dodatkowych, okresowych i cyklicznych opłat w okresach 6 lub 12 miesięcznych przez podmiot wnioskujący w zależności od wybrania jednej z poniższych opcji:</p>
    <p>a/ 7,87 złotych brutto przy założeniu opłaty z góry łącznie za okres 12 m-cy, które każdorazowo należy dodać do kwoty zapisanej w niniejszym Cenniku w Paragrafie 1 Punkt 2a, tj. do kwoty 15,87 złotych brutto</p>
    <p>b/ 9,87 złotych brutto przy założeniu opłaty z góry łącznie na okres 6 m-cy, które każdorazowo należy dodać do kwoty zapisanej w niniejszym Cenniku w Paragrafie 1
    Punkt 2b, tj. do kwoty 29,87 złotych brutto</p>
    <p>5. Dodatkowe wyróżnienia, o których mowa w Punkcie 1 niniejszego paragrafu można dowolnie konfigurować, tzn. można nie wybrać dodatkowego wyróżnienia, można wybrać jedno, dwa lub trzy wyróżnienia. Jedynym uwarunkowaniem jest wybór jednego z okresów zapisanego w Punkcie 2 §1, tj. 6 lub 12 miesięcznego okresu umieszczenia podmiotu na Platformie, co warunkuje wybór tego samego okresu, tj.  6 lub 12 miesięcznego przy wyborze dodatkowego, dowolnego wyróżnienia i/lub wyróżnień, co oznacza, że wybór np. 12 miesięcznego okresu uwidocznienia podmiotu na platformie zgodnie z zapisami Punktu 2a §1 jest równoznaczny z koniecznością tożsamego wyboru obowiązywania dodatkowego wyróżnienia i/lub wyróżnień, czyli w tym przypadku również na okres 12 miesięcy. </p>
    <p class="center">§3.</p>
    <p>1. Partner po zakończeniu wybranego i opłaconego okresu 6 lub 12 miesięcy umieszczenia Wizytówki na Platformie, zgodnie z zapisami Regulaminu Platformy oraz zapisem § 1 niniejszego Cennika może zakończyć współpracę lub zgłosić akces do jej przedłużenia.</p>
    <p>2. W przypadku zakończenia współpracy należy kierować się zapisami Regulaminu, a w przypadku chęci kontynuowania Partner jest obowiązany do wyboru okresu współpracy i poniesienia okresowych, cyklicznych opłat w okresach 6 lub 12 miesięcznych przez podmiot w zależności od wybrania jednej z opcji zapisanych w § 1 punkt 2.</p>
    <p class="center">§4</p>
    <p>1. Podmiot, który decyzją Administratora stał się Partnerem Platformy, ma również możliwość umieszczenia artykułów sponsorskich, być umieszczonym na sliderach reklamowych widocznych na Platformie oraz wziąć udział w akcjach marketingowych na związanych z Portalem forach w mediach społecznościowych.</p>
    <p>2. Decyzja o współpracy w tym zakresie jest decyzją autonomiczną Administratora i jest możliwa tylko po ustaleniu i zaakceptowaniu przez Administratora warunków uczestnictwa Partnera w tychże.</p>
    <p class="center">§5</p>
    <p>1. Administrator zastrzega sobie prawo do wprowadzania zmian w zapisach niniejszego Cennika, w tym wysokości opłat, o czym obowiązuje się poinformować na swoim Portalu. </p>
    <p class="center">§6</p>
    <p>1. Wszelkie pozostałe kwestie regulują zapisy Regulaminu, którego Cennik jest załącznikiem i tym samym integralną częścią.</p>


    </div>
</div>
</template>

<style scoped>
.policy-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px auto;
}
.policy-container div {
    width: 1300px;
}
div p {
    text-align: justify;
}
.center {
    text-align: center;
}
.center-bold {
    text-align: center;
    font-weight: bold;
}
@media only screen and (max-width: 1300px) {
    .policy-container div {
    width: 100%;
    box-sizing: border-box;
    padding:10px;
    font-size: 15px;
}
}
</style>