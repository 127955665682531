import { createStore } from 'vuex';

const store = createStore({

//export default function createStore({
    state() {
        return {
            categories: [],
            subcategories: []
        };
    },
    mutations: {
        setCategories(state, category) {
            this.state.categories = category;
        },
        setSubcategories(state, subcategories) {
            this.state.subcategories = subcategories;
        }
    },
    actions: {

    }
});


export default store;