import axios from 'axios'



const USER_API_URL = 'http://localhost:4000'

class CompaniesDataService {

    retrieveAllCompanies() {

        return axios.get(`${USER_API_URL}/fetch.php`).then(response => response)
        .then(data => {
            console.log(data);
        })
        .catch((error) => {
          console.error('Error:', error);
          console.log("server is down!!")
        });
    }
    createCompany(user) {
        console.log(user);
        return axios.post(`${USER_API_URL}/upload.php`, user);
        //return axios.post(`${USER_API_URL}/create.php`, user);
    }
    retriveAllLogos() {
        return axios.get(`${USER_API_URL}/fetch.php`)
        // generator URI PHP




        // axios({
        //     url: 'https://codewithblonde.com/photos', //your url
        //     method: 'GET',
        //     responseType: 'blob', // important
        // })
        // .then(res => {
        //   this.photos = res.data;
        // })
    }


}

export default new CompaniesDataService()