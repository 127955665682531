<template>
    <div class="top-header-container">
    <div class="logo-container">
        <router-link to='/'><img :src="logo" class="top-logo"/></router-link>
    </div>
    <div class="wrapper">
        <div id="sidemenu">
            <button class="sidemenu__btn" v-on:click="navOpen=!navOpen" v-bind:class="{active:navOpen}">
				<span class="top"></span>
				<span class="mid"></span>
				<span class="bottom"></span>
			</button>
    <transition name="translateX">
      <nav v-show="navOpen">
        <div class="sidemenu__wrapper">
          <ul class="sidemenu__list">
            <li class="sidemenu__item"><router-link to='/aboutUs'>O nas</router-link></li>
            <li class="sidemenu__item"><router-link to='/certyfikat'>Certyfikat</router-link></li>
            <li class="sidemenu__item"><router-link to='/bePartner'>Zostań Partnerem</router-link></li>
            <li class="sidemenu__item"><a href='https://blog.mendelhelps.pl'>Blog</a></li>
            <li class="sidemenu__item"><router-link to='/contact'>Kontakt</router-link></li>
          </ul>
        </div>
      </nav>
    </transition>
  </div>
  </div>
        <div class="menu">
            <router-link to='/aboutUs'><p>O nas</p></router-link>
            <router-link to='/certyfikat'><p>Certyfikat</p></router-link>
            <router-link to='/bePartner'><p>Zostań naszym Partnerem</p></router-link>
            <a href='https://blog.mendelhelps.pl'><p>Blog</p></a>
            <router-link to='/contact'><p>Kontakt</p></router-link>
        </div>
        <div class="social-media">
            <router-link to='#'><img :src="facebookIcon" class="socialMedia-icon"/></router-link>
            <router-link to='#'><img :src="instagramIcon" class="socialMedia-icon"/></router-link>
        </div>
    </div>
</template>

<script>
import logo from "../photos/Mendel_Helps_maly_sygnet.png";
import facebook from "../photos/facebook_logo.png";
import instagram from "../photos/ig_logo.png";
export default {
    data() {
        return {
            navOpen: false,
            logo: logo,
            facebookIcon: facebook,
            instagramIcon: instagram
        }
    }
}
</script>

<style scoped>
.top-header-container {
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 0;
    background-image: linear-gradient(to right, #5D4DB7 , #3084E2);
    border-radius: 0 0 30px 30px;
    z-index: 100;
    position: sticky;
}
.menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}
.menu p {
    color: #303845;
    font-weight: bold;
    margin: 0 30px;
    transition: 0.3s ease-out;
    font-weight: 500;
    font-size: 19px;
    color: white;
}
.menu p:hover {
    transform: scale(1.1);
}
.socialMedia-icon {
    width: 40px;
    margin: 0 10px;
}
a {
    text-decoration: none;
    cursor: pointer;
}
.top-logo {
    padding: 20px;
    width: 60px;
}
#sidemenu {
    display: none;
    position: absolute;
    top: 0;
    align-self: center;
}
nav {
    width: 200px;
    background: #3084E2;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    font-size: 17px;
    z-index: 99;
/*// box-shadow: 2px 0 3px;
// overflow-y: scroll;*/
}

button {
    display: block;
    width: 50px;
    height: 50px;
    background: white;
    border: none;
    position: relative;
    z-index: 100;
    appearance: none;
    cursor: pointer;
    outline: none;
}

span {
    display: block;
    width: 20px;
    height: 2px;
    margin: auto;
    background: gray;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all .7s ease;
}
.top {
    transform: translateY(-8px);
}
.bottom {
    transform: translateY(8px);
}
.active .top {
    transform: rotate(-45deg);
}
.active .mid {
    transform: translateX(-20px) rotate(360deg);
    opacity: 0;
}
.active .bottom {
    transform: rotate(45deg);
}
.sidemenu__wrapper {
      padding-top: 50px;
    }
.sidemenu__list {
    padding-top: 50px;
    list-style:none;
    padding: 0;
    margin: 0;
}
.sidemenu__item a {
    text-decoration: none;
    line-height: 1.6em;
    font-size: 1.6em;
    padding: .5em;
    display: block;
    color: white;
    transition: .5s ease;
}
.sidemenu__item a:hover {
    background: lightgrey;
    color: dimgrey;
}
.translateX-enter{
    transform:translateX(-200px);
    opacity: 0;
}

.translateX-enter-active,.translateX-leave-active{
    transform-origin: top left 0;
    transition:.5s ease;
}

.translateX-leave-to{
    transform: translateX(-200px);
    opacity: 0;
}


@media only screen and (min-width: 600px) and (max-width: 900px) {
.menu p {
    color: #303845;
    font-weight: bold;
    margin: 0 10px;
    transition: 0.3s ease-out;
    font-weight: 500;
    font-size: 14px;
    color: white;
}
.socialMedia-icon {
    width: 30px;
    margin: 3px 10px;
}
}
@media only screen and (max-width: 600px) {
#sidemenu {
    display: block;
}
.header-container {
    width: 100vw;
}
.menu {
    display: none;
}
.socialMedia-icon {
    width: 30px;
    margin: 0 10px;
}
.sidemenu__btn {
    position: absolute;
    align-self: center;
    top: 50%;
    left: 50%;
    margin-top: 20px; /* Negative half of height. */
    margin-left: -25px;
}
.social-media {
    width: 30%;
}
.logo-container {
    width: 30%;
}
.logo {
    padding: 10px;
    width: 50px;
}
.top-header-container {
    margin:0;
    height: 90px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    }
}
</style>