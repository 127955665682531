<template>
    <form @submit="addCompany">
    <div>
        <div>
            <label>Nazwa producenta</label>
            <input type="text" v-model="company.prodName">
        </div>
        <div>
            <label>Adres producenta</label>
            <input type="text" v-model="company.prodAdress">
        </div>
        <div>
            <label>Województwo</label>
            <input type="text" v-model="company.woj">
        </div>
        <div>
            <label>Adres mailowy</label>
            <input type="text" v-model="company.mail">
        </div>
        <div>
            <label>Numer telefonu</label>
            <input type="text" v-model="company.tel">
        </div>
        <div>
            <label>Adres strony</label>
            <input type="text" v-model="company.webSite">
        </div>
        <div>
            <label>Kategoria</label>
            <select v-model="chosenCategory">
                <option disabled value="">Wybierz</option>
                <option v-for="category in allCategories" :key="category.name">
                {{ category.name }}
                </option>
            </select>
        </div>

        <div v-for="type in this.typesFromCategory.types" :key="type">
            <input type="checkbox" id="subcategory" name="subcategory" :value="type"/>
            <label for="subcategory">{{ type }}</label><br>
        </div>
        <input type="submit"/>
    </div>
    </form>
</template>

<script>
import CompaniesDataService from "../GetCompanies.js";
import categories from "../categories.js";
export default {
    data() {
        return {
            company: {
                prodName:"",
                prodAdress: "",
                woj: "",
                mail: "",
                tel: "",
                webSite: "",
                //category:"",
                subcategories: {
                    dzemy: false,
                    powidła: false,
                    konfitury:false,
                    musy: false,
                    inneDzemy: false,
                    maki: false,
                    kasze: false,
                    przetowyZbozowe: false,
                    miesoWieprzowe: false,
                    miesoWolowe: false,
                    miesoDrobiowe: false,
                    miesoCielence: false,
                    inneMieso: false,
                    wedliny: false,
                    wyrobyWedliniarskie: false
                }
            },
            allCategories: categories,
            chosenCategory:"",
            typesFromCategory: []
        }
    },
    methods: {
        addCompany() {
            event.preventDefault();

            let checkboxes =
                document.getElementsByName('subcategory');
            let checkedSubcategories = [];
            for (var i = 0; i < checkboxes.length; i++) {
                if (checkboxes[i].checked) {
                    checkedSubcategories.push(checkboxes[i].value);
                }
            }
            this.company.subcategories.dzemy = checkedSubcategories.includes("dżemy");
            this.company.subcategories.powidla = checkedSubcategories.includes("powidła");
            this.company.subcategories.konfitury = checkedSubcategories.includes("konfitury");
            this.company.subcategories.musy = checkedSubcategories.includes("musy");
            this.company.subcategories.inneDzemy = checkedSubcategories.includes("inne");
            this.company.subcategories.maki = checkedSubcategories.includes("mąki");
            this.company.subcategories.kasze = checkedSubcategories.includes("kasze");
            this.company.subcategories.przetowyZbozowe = checkedSubcategories.includes("przetwory zbożowe");
            this.company.subcategories.miesoWieprzowe = checkedSubcategories.includes("mięso wieprzowe");
            this.company.subcategories.miesoWolowe = checkedSubcategories.includes("mięso wołowe");
            this.company.subcategories.miesoDrobiowe = checkedSubcategories.includes("mięso drobiowe");
            this.company.subcategories.miesoCielence = checkedSubcategories.includes("cielęcina");
            this.company.subcategories.inneMieso = checkedSubcategories.includes("inne mięso");
            this.company.subcategories.wedliny = checkedSubcategories.includes("wędliny");
            this.company.subcategories.wyrobyWedliniarskie = checkedSubcategories.includes("wyroby wędliniarskie");


            console.log(this.company.subcategories.dzemy);
            console.log(this.company.subcategories.powidla);
            console.log(this.company.subcategories.konfitury);
            console.log(this.company.subcategories.musy);
            console.log(this.company.subcategories.inneDzemy);
            console.log(this.company.subcategories.maki);
            console.log(checkedSubcategories);



            CompaniesDataService.createCompany({
                woj: this.company.woj,
                prodName: this.company.prodName,
                prodAdress: this.company.prodAdress,
                mail: this.company.mail,
                tel: this.company.tel,
                webSite: this.company.webSite,
                category: this.chosenCategory,
                dzemy: this.company.subcategories.dzemy,
                powidla: this.company.subcategories.powidla,
                konfitury: this.company.subcategories.konfitury,
                musy: this.company.subcategories.musy,
                inneDzemy: this.company.subcategories.inneDzemy,
                maki: this.company.subcategories.maki,
                kasze: this.company.subcategories.kasze,
                przetowyZbozowe: this.company.subcategories.przetowyZbozowe,
                miesoWieprzowe: this.company.subcategories.miesoWieprzowe,
                miesoWolowe: this.company.subcategories.miesoWolowe,
                miesoDrobiowe: this.company.subcategories.miesoDrobiowe,
                miesoCielence: this.company.subcategories.miesoCielence,
                inneMieso: this.company.subcategories.inneMieso,
                wedliny: this.company.subcategories.wedliny,
                wyrobyWedliniarskie: this.company.subcategories.wyrobyWedliniarskie,
            })
        }
    },
    watch: {
        chosenCategory() {
            let category = this.chosenCategory;
            let typesArray = this.allCategories.find(
          (option) => option.name == category);
            this.typesFromCategory = typesArray;
        }
    }
}
</script>

<style scoped>
form {
    margin: 100px;
}
div {
    margin: 15px;
}
</style>