
const categories = [
    {name: 'dżemy, musy, konfitury, inne',
        types: [
            'dżemy', 'powidła', 'konfitury', 'musy', 'inne'
        ],
    },
    {name: 'mąki, kasze, produkty zbożowe',
        types: [
            'mąki', 'kasze', 'przetwory zbożowe'
        ],
    },
    {name: 'mięso i przetwory mięsne',
        types: [
            'mięso wieprzowe', 'mięso wołowe', 'mięso drobiowe', 'cielęcina', 'inne mięso', 'wędliny', 'wyroby wędliniarskie'
        ],
    },
    {name: 'miód i produkty pszczele',
        types: [
            'rzepakowy', 'lipowy', 'akacjowy', 'gryczany', 'wielokwiatowy', 'spadziowy', 'wrzosowy', 'malinowy', 'mniszkowy', 'nawłociowy', 'faceliowy', 'leśny', 'inne miody', 'ziołomiody', 'pierzga', 'pyłek kwiatowy', 'propolis', 'świece', 'wosk pszczeli'
        ],
    },
    {name: 'nabiał',
        types: [
            'sery z mleka krowiego', 'sery z mleka koziego', 'sery z mleka owczego', 'jogurty', 'inne produkty nabiałowe'
        ],
    },
    {name: 'napoje alkoholowe',
        types: [
            'wódki', 'nalewki', 'wina', 'piwa', 'inne alkohole'
        ],
    },
    {name: 'natura wspomaga zdrowie',
        types: [
            'zioła, herbaty', 'preparaty ziołowe', 'produkty pszczele', 'soki, syropy', 'nalewki, balsamy', 'maści, kremy, żele', 'kosmetyki', 'inne produkty'
        ],
    },
    {name: 'octy',
        types: [
            'jabłkowy', 'malinowy', 'jeżynowy', 'gruszkowy', 'z głogu', 'z pigwy', 'z róży', 'z winogron', 'z kwiatu czarnego bzu', 'inne octy'
        ],
    },
    {name: 'oleje',
        types: [
            'z lnu', 'z gorczycy białej', 'rydzowy', 'z pestek dyni', 'z pestek czarnej maliny', 'z otropestu', 'z czarnuszki', 'z wiesiołka', 'z krokosza', 'inne oleje'
        ],
    },
    {name: 'produkty kosmetyczne',
        types: [
            'do twarzy', 'do ciała', 'do kąpieli', 'mydła'
        ],
    },
    {name: 'przetwory warzywne, marynaty',
        types: [
            'produkty kwaszone', 'marynaty', 'sałatki', 'pasty', 'przeciery', 'inne przetwory warzywne'
        ],
    },
    {name: 'ryby i przetwory rybne',
        types: [
            'żywe/świeże', 'wędzone', 'w occie', 'w oleju', 'w galarecie', 'paprykarze', 'pasty', 'inne przetwory rybne'
        ],
    },
    {name: 'soki',
        types: [
            'owocowe', 'warzywne', 'owocowo-warzywne'
        ],
    },
    {name: 'sosy, musztardy, przyprawy',
    types: [
        'sosy', 'musztardy', 'ketchupy', 'przyprawy', 'inne przyprawy'
        ],
    },
    {name: 'susze',
    types: [
        'suszone owoce', 'suszone warzywa', 'suszone mieszanki', 'suszone grzyby', 'liofilizowane owoce', 'liofilizowane warzywa', 'liofilizowane mieszanki', 'liofilizowane grzyby', 'inne susze'
        ],
    },
    {name: 'syropy',
    types: [
        'malina', 'czarny bez kwiat', 'czarny bez owoc', 'mniszek lekarski', 'czarna porzeczka', 'jeżyna', 'wiśnia', 'aronia', 'dzika róża', 'jagoda kamczacka', 'pigwowiec', 'truskawka', 'inne syropy'
        ],
    },
    {name: 'warzywa i owoce',
    types: [
        'warzywa korzeniowe (marchew, pietruszka, seler)', 'warzywa kapustne (kapusta, brokuł, kalafior)', 'warzywa liściowe (sałata, szpinak, seler naciowy)', 'warzywa cebulowe (cebula, czosnek, por)', 'warzywa psiankowate (papryka, pomidor, ziemniak)', 'warzywa dyniowate (dynia, kabaczek, melon, ogórek)', 'owoce ziarnkowe (jabłko, gruszka, granat)', 'owoce pestkowe (śliwki, czereśnie, morele)', 'owoce jagodowe (truskawka, malina, jeżyna)', 'owoce cytrusowe (pomarańcze, cytryny, mandarynki)'
        ],
    },
    {name: 'zioła',
    types: [
        'zioła lecznicze', 'zioła przyprawowe', 'zioła olejkodajne', 'herbaty ziołowe/owocowe'
        ],
    },
]

export default categories;