<template>
<div class="certificate-container">
    <div class="text-container">
        <div class="cert-first-para-container">
            <p class="certificate-first-paragraph">Jesteś rolnikiem, wytwórcą lub świadczysz usługi o ponadprzeciętnej jakości,</p>
            <p class="certificate-first-paragraph">zostań nie tylko naszym Partnerem, ale także bądź wyróżniony</p>
            <p class="certificate-first-paragraph">Certyfikatem Mendel Helps.</p>
        </div>
        <div class="img-container-cert">
            <img :src="images.img2" alt="Certyfikat Mendel Helps" class="certificate"/>
        </div>
        <p><b>CERTIFICATE MENDEL HELPS</b> jest świadectwem ponadprzeciętnej jakości wytwarzanego produktu
        i świadczonych usług, stylu prowadzenia działalności, szacunku dla otoczenia,
        przyrody i drugiego człowieka. O jego przyznanie mogą ubiegać się wytwórcy i producenci
        produktów rolno-spożywczych, kosmetycznych oraz świadczący usługi, takie jak usługi HORECA,
        usługi rehabilitacyjne, lecznicze, uzdrowiskowe, sanatoryjne, z zakresu działalności fitness,
        wellness, spa itp., będącymi Partnerami Portalu Mendel Helps Life, przy czym należy zaznaczyć,
        iż wymieniony powyżej zakres produktów i usług nie ma charakteru wyczerpującego. Certyfikat
        przyznawany jest decyzją powołanej wyłącznie do tego celu Kapituły Certyfikatu Mendel Helps, w
        składzie której zasiadają wysokiej klasy specjaliści, menedżerowie oraz powszechne uznane i
        szanowane autorytety. Kryteria, zasady i tryb przyznawania Certyfikatu określa szczegółowo Regulamin
        Certyfikatu Mendel Helps, który jest każdorazowo udostępniany i omawiany z wnioskującymi, po uprzednio
        wydanej pozytywnej wstępnej decyzji Kapituły Certyfikatu. </p>
        <p>Chcemy wspólnie z wyróżnionymi budować prestiż Certyfikatu oraz promować jego ideę. Promować jakość,
        wyjątkowe walory smakowe, najwyższy poziom usług i wyjątkową dbałość o klienta oraz tak potrzebny w
        dzisiejszych czasach szacunek. Tworzyć przekaz, że Certyfikat Mendel Helps jest gwarancją dla każdego
        konsumenta ponadprzeciętności w każdym obszarze i w każdej chwili. Certyfikat Mendel Helps to wyjątkowość
        i nietuzinkowość, a nade wszystko to dbałość o jakość w najmniejszym detalu w procesie wytwarzania produktu
        i świadczenia usług. W naszym przypadku nie ma miejsca na bylejakość, Certyfikat Mendel Helps stoi na straży
        i jest gwarantem ponadprzeciętnej jakości dla swoich, wszystkich Użytkowników. </p>
        <p class="invite">ZAPRASZAMY DO KONTAKTU W CELU OMÓWIENIA SZCZEGÓŁÓW</p>
        <div class="sentence-container">
            <p class="sentence">Zawsze, kiedy potrzebujesz pomocy...<img :src="images.img1" alt="Mendel Helps"/></p>
        </div>
    </div>
</div>
</template>
<script>
import imgLogo from '../photos/Mendel_Helps_sentence.png';
import certificate from '../photos/certyfikat_na_strone.png';
export default {
     data() {
            return {
                images: {
                    img1: imgLogo,
                    img2: certificate
                }
            }
        },
}
</script>
<style scoped>
.cert-first-para-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.certificate-container {
    width: 1000px;
    margin: 50px auto;
    margin: 0 auto;
    text-align: justify;
}
.sentence-container {
    width:100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.certificate {
    width: 650px;
}
.text-container {
    margin: 50px 0;
}
.certificate-first-paragraph {
    font-size: 19px;
    font-style: italic;
    color: #3084E2;
    font-weight: 600;
    margin: 5px 0;
    padding: 0;
}
.invite {
    font-weight: 600;
    font-size: 19px;
    align-self: center;
}
.img-container-cert {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}
@media only screen and (max-width: 1000px) {
    .certificate-container {
        width: 100%;
        box-sizing: border-box;
        height: auto;
        padding: 20px;
        margin: 50px auto;
        margin: 0 auto;
        text-align: justify;
    }
    .img-container-cert img {
        width: 100%;
    }
    .cert-first-para-container p{
    text-align: center;
    }
    .sentence-container p {
        text-align: left;
    }
    .text-container {
        margin: 0;
    }
}
</style>