//import Vue from 'vue'
import { createApp } from 'vue'
import { router  } from './routes.js'
import  store  from './store/index.js';
import App from './App.vue';
import { createHead } from '@vueuse/head';
import VueGtag from "vue-gtag"; //google analytics

//Vue.config.productionTip = false;

// import VueCookie from 'vue-cookie';
const head = createHead();
//import VueMeta from 'vue-meta'
const app = createApp(App);
//app.use(VueMeta);
app.use(store);
app.use(router);
app.use(head);
app.use(VueGtag, {
    config: { id: "YOUR_MEASUREMENT_ID",
    productionTip: false }
  });
// app.use(VueCookie);
app.mount('#app');
