<template>
<div class="policy-container">
    <div>
        <h3>Polityka prywatności</h3>

        <p>1. Administratorem Państwa danych osobowych jest PIOTR MENDEL MANUFAKTURA, Aleja Bolesława Krzywoustego 4/1, 40-870 Katowice, email: biurolife@mendelhelps.pl, zwany dalej Administratorem.</p>
        <p>2. Administrator przedsięwziął odpowiednie kroki, wdrożył procedury, a także stosowne środki techniczne celem zapewnienia odpowiedniego stopnia zabezpieczenia danych osobowych, mając na uwadze zarówno dobro swoich Partnerów, Użytkowników jak i przepisy prawa dotyczące ochrony danych osobowych.</p>
        <p>3. Administrator, z uwagi na zasadę minimalizmu, przetwarza jedynie te dane, które są niezbędne do realizacji celów, o których mowa w Punkcie 4 niniejszej Polityki.</p>
        <p>4. Podanie przez wszystkich korzystających z Portalu danych osobowych jest dobrowolne. Jednakże brak ich podania uniemożliwia nawiązanie współpracy i korzystanie z Portalu.</p>
        <p>5. Źródłem danych osobowych wykorzystywanych przez Administratora są osoby, podmioty, które przedmiotowe dane przekazały dobrowolnie Administratorowi i tym samym, których te dane dotyczą.</p>
        <p>5. Biorąc pod uwagę zapisu Punktu 4 niniejszej Polityki, podane dane osobowe Administrator przetwarza w celu:</p>
        <p>a/ nawiązania współpracy;</p>
        <p>b/ realizacji usług Portalu oraz rozwijania współpracy Administratora z podmiotami, osobami w oparciu o zapisy zawarte w Regulaminie Portalu oraz innych dokumentach regulujących współpracę Administratora z użytkownikami Portalu;</p>
        <p>c/ umożliwienia kontaktu Administratora z Partnerem co jest koniecznym w celu prawidłowej realizacji współpracy określonej w Regulaminie Portalu Administratora;</p>
        <p>d/ umożliwienie kontaktu Użytkownikowi Portalu w celach opisanych w Regulaminie Portalu;</p>
        <p>e) realizacji usług Portalu Administratora na rzecz Partnerów i Użytkowników Portalu oraz realizacji idei Portalu zapisanego w Regulaminie Portalu;</p>
        <p>Przetwarzanie danych w celach zapisanych w podpunktach od „a” do „e” Punktu 4 niniejszej Polityki prywatności, trwać będzie do zakończenia współpracy pomiędzy Administratorem i Partnerem i/lub Użytkownikiem Portalu;</p>
        <p>f/ realizacji zapisów i wymogów obowiązującego prawa spoczywającym na Administratorze;</p>
        <p>g/ ewentualnej obrony interesów Administratora w przypadku wystąpienia roszczeń względem Administratora;</p>
        <p>i/ wywiązania się Administratora z obowiązków spoczywających na nim, a mającym swe źródło w przepisach obowiązującego prawa.</p>
        <p>Przetwarzanie danych w celach zapisanych w podpunktach od „f” do „i” Punktu 4 niniejszej Polityki prywatności, trwać będzie przez okres wymagany i wynikających z przepisów prawa, o których mowa w tychże przepisach.</p>
        <p>6. Dane osobowe podmiotów, Partnerów i Użytkowników Portalu Administrator może powierzyć do przetwarzania:</p>
        <p>a/ podmiotom upoważnionym z mocy obowiązującego prawa;</p>
        <p>b/ Użytkownikom i Partnerom Portalu;</p>
        <p>d/ osobom, podmiotom będącymi odbiorcami usług świadczonych i oferowanych przez Portal;</p>
        <p>b/ podmiotom świadczącym usługi na rzecz Administratora bezpośrednio i/lub pośrednio związane z funkcjonowaniem, utrzymaniem, rozwojem i promocją Portalu, którym Administrator powierzył przetwarzanie danych osobowych i/lub którzy świadczą na rzecz Administratora usługi, które są ważne i niezbędne dla funkcjonowania i szeroko rozumianego rozwoju działalności Administratora. Przetwarzanie tych danych jest możliwe na podstawie odrębnych umów i za poszanowaniem przepisów obowiązującego prawa i zapisów niniejszej Polityki.</p>
        <p>7. Powierzone Administratorowi dane osobowe nie będą poddawane przetwarzaniu transgranicznemu, nie będą przekazywane do państw trzecich i/lub organizacji międzynarodowych.</p>
        <p>8. Przekazującym swoje dane osobowe Administratorowi przysługują następujące prawa:</p>
        <p>a/ bycia informowanym o przetwarzaniu swoich danych osobowych;</p>
        <p>b/ dostępu do swoich danych osobowych;</p>
        <p>c/ poprawiania, uzupełniania, uaktualniania i sprostowania swoich danych osobowych;</p>
        <p>d/ żądania do usunięcia swoich danych osobowych;</p>
        <p>e/ ograniczenia przetwarzania swoich danych osobowych;</p>
        <p>f/ przenoszenia swoich danych osobowych;</p>
        <p>g/ wniesienia sprzeciwu wobec przetwarzania swoich danych osobowych;</p>
        <p>h/ cofnięcia zgody na przetwarzanie swoich danych osobowych;</p>
        <p>i/ usunięcia swoich danych osobowych;</p>
        <p>j/ przeniesienia swoich danych osobowych do innego administratora danych osobowych.</p>
        <p>9. Poza uprawnieniami wskazanymi w Punkcie 8 niniejszej Polityki, przekazującemu swoje dane osobowe przysługuje prawo do wniesienia skargi do organu nadzorczego, jakim jest Prezes Urzędu Ochrony Danych Osobowych urzędującego pod adresem: 00-193 Warszawa, ulica Stawki 2, w przypadku uznania przez przekazującego swoje dane osobowe, że przetwarzanie w/w danych przez Administratora narusza przepisy RODO.</p>
        <p>10. W przypadku kwestii nieuregulowanych przez zapisy niniejszej Polityki prywatności, stosuje się przepisy prawa powszechnie obowiązującego.</p>
    </div>
</div>
</template>

<style scoped>
.policy-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px auto;
}
.policy-container div {
    width: 1000px;
}
div p {
    text-align: justify;
}
@media only screen and (max-width: 1000px) {
.policy-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    margin: 30px auto;
}
.policy-container div {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
}
}
</style>