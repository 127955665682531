<template>
    <div class="container-main">
        <!--<Flicking :options="{ align: 'prev', circular: true }" @move-end="onMoveEnd"  :firstPanelSize="'200px'" :plugins="plugins">
            <div class="panel">Jestem pierwszym panelem</div>
            <div class="panel">Jestem drugim panelem</div>
            <div class="panel">Jestem trzecim panelem</div>
        </Flicking>-->
            <div class="hight-carusel-conteiner">
                <Flicking :options="{ circular: true }" :plugins="plugins1">
                    <div class="sentence-container" id="firstSentence">
                        <p class="sentence">Zawsze, kiedy potrzebujesz pomocy...<img :src="backgrounds.img11" alt="Mendel Helps"/></p>
                    </div>
                    <div class="sentence-container" id="secondSentence">
                        <p class="sentence">Zawsze, kiedy potrzebujesz pomocy...<img src="../../public/photos/Mendel_Helps_sentence1.png"/></p>
                    </div>
                </Flicking>
            </div>
            <div class="header-container2">
                <div class="header2">
                    <div class="carusel-container">
                        <Flicking :options="{ circular: true }" :plugins="plugins">
                                <div class="card-panel" id="first-slide">
                                    <div class="text-inside-slider">
                                        <img src="../../public/photos/hand.png" class="hand"/>
                                        <h2>ZOSTAŃ NASZYM PARTNEREM</h2>
                                        <p class="italic-small">Nasz wymóg to ponadprzeciętna jakość wytwarzanych produktów i świadczonych usług.</p>
                                        <p class="bold-big">Baza Portalu to najlepsze podmioty z kategorii:</p>
                                        <ul>
                                            <li class="bold-big">Produkty</li>
                                            <li class="bold-big">Wypoczynek</li>
                                            <li class="bold-big">Podreperuj zdrowie</li>
                                        </ul>
                                        <div class="sentence-container1" id="secondSentence">
                                            <p class="sentence1">Zaprasza Portal <img src="../../public/photos/Mendel_Helps_bialy1.png" class="logo_life_pod_lupa"/></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-panel" id="first-slide11">
                                    <div class="text-inside-slider">
                                        <img src="../../public/photos/hand.png" class="hand"/>
                                        <div class="sentence-container1" id="secondSentence1">
                                            <img src="../../public/photos/Mendel_Helps_sygnet_kolor_maly.png" alt="MendelHelps logo" class="sygnet-slider"/>
                                            <p class="sentence11">Zawsze kiedy potrzebujesz pomocy...</p>
                                            <img src="../../public/photos/Mendel_Helps_logo.png" class="logo_life_pod_lupa"/>
                                            <h1 class="sentence12">Mendel Helps</h1>
                                        </div>
                                    </div>
                            </div>
                            <div class="card-panel" id="second-slide">
                            <div class="text-inside-slider">
                                <img src="../../public/photos/hand.png" class="hand"/>
                                <h2>ZDROWE ODŻYWIANIE</h2>
                                    <p class="italic-small">Człowiek jest tym co zje.</p>
                                   <p class="bold-big">Poznaj:</p>
                                    <ul>
                                        <li class="bold-big">produkty i ich wpływ na nasze zdrowie</li>
                                        <li class="bold-big">przepisy na proste, smaczne dania</li>
                                    </ul>
                                    <div class="sentence-container1" id="secondSentence">
                                        <p class="sentence1">Co jeść a czego nie jeść, dowiesz się dzięki <img src="../../public/photos/Mendel_Helps_logo.png" class="logo_life_pod_lupa"/></p>
                                    </div>
                                </div>
                            </div>
                            <div class="card-panel" id="first-slide1">
                                    <div class="text-inside-slider">
                                        <img src="../../public/photos/hand.png" class="hand"/>
                                        <h2>ZOSTAŃ NASZYM PARTNEREM</h2>
                                        <p class="italic-small">Nasz wymóg to ponadprzeciętna jakość wytwarzanych produktów i świadczonych usług.</p>
                                        <p class="bold-big">Baza Portalu to najlepsze podmioty z kategorii:</p>
                                        <ul>
                                            <li class="bold-big">Produkty</li>
                                            <li class="bold-big">Wypoczynek</li>
                                            <li class="bold-big">Podreperuj zdrowie</li>
                                        </ul>
                                        <div class="sentence-container1" id="secondSentence">
                                            <p class="sentence1">Zaprasza Portal <img src="../../public/photos/Mendel_Helps_bialy1.png" class="logo_life_pod_lupa"/></p>
                                        </div>
                                    </div>
                            </div>
                            <div class="card-panel" id="third-slide">
                                <div class="text-inside-slider">
                                <img src="../../public/photos/hand.png" class="hand"/>
                                <h2>PRODUKTY</h2>
                                    <p class="italic-small">Szukasz produktów o ponadprzeciętnej jakości?</p>
                                    <p class="bold-big">Tylko tutaj:</p>
                                    <ul>
                                        <li class="bold-big">Pełna gama produktów</li>
                                        <li class="bold-big">Najwyższej klasy wytwórców i producentów</li>
                                    </ul>
                                    <div class="sentence-container1" id="secondSentence">
                                        <p class="sentence1">Nie zwlekaj, zajrzyj już teraz <img src="../../public/photos/Mendel_Helps_logo.png" class="logo_life_pod_lupa"/></p>
                                    </div>
                                </div>
                            </div>
                            <div class="card-panel" id="first-slide2">
                                    <div class="text-inside-slider">
                                        <img src="../../public/photos/hand.png" class="hand"/>
                                        <h2>ZOSTAŃ NASZYM PARTNEREM</h2>
                                        <p class="italic-small">Nasz wymóg to ponadprzeciętna jakość wytwarzanych produktów i świadczonych usług.</p>
                                        <p class="bold-big">Baza Portalu to najlepsze podmioty z kategorii:</p>
                                        <ul>
                                            <li class="bold-big">Produkty</li>
                                            <li class="bold-big">Wypoczynek</li>
                                            <li class="bold-big">Podreperuj zdrowie</li>
                                        </ul>
                                        <div class="sentence-container1" id="secondSentence">
                                            <p class="sentence1">Zaprasza Portal <img src="../../public/photos/Mendel_Helps_bialy1.png" class="logo_life_pod_lupa"/></p>
                                        </div>
                                    </div>
                            </div>
                            <div class="card-panel" id="fourth-slide">
                                <div class="text-inside-slider">
                                <img src="../../public/photos/hand.png" class="hand"/>
                                <h2>POD LUPĄ</h2>
                                    <p class="italic-small">Czy oferowane produkty są dobrej jakości i warte swej ceny?<br/> Czy w usługach jest fachowo, smacznie i zwyczajnie czysto?</p>
                                    <div>
                                        <p class="big-bold">Nasze zasady:</p>
                                        <ul>
                                            <li class="bold-big">Ganimy wszystko to co złe, chwalimy to co dobre</li>
                                            <li class="bold-big">Nie zgadzamy się na bylejakość</li>
                                        </ul>
                                    </div>
                                    <div class="sentence-container12" id="secondSentence">
                                        <p class="sentence1">Podziel się swoimi doświadczeniami</p>
                                    </div>
                                    <div class="sentence-container1" id="secondSentence">
                                        <p class="sentence1">Do lektury zaprasza <img src="../../public/photos/Mendel_Helps_logo.png" class="logo_life_pod_lupa"/></p>
                                    </div>
                                </div>
                            </div>
                            <div class="card-panel" id="first-slide3">
                                    <div class="text-inside-slider">
                                        <img src="../../public/photos/hand.png" class="hand"/>
                                        <h2>ZOSTAŃ NASZYM PARTNEREM</h2>
                                        <p class="italic-small">Nasz wymóg to ponadprzeciętna jakość wytwarzanych produktów i świadczonych usług.</p>
                                        <p class="bold-big">Baza Portalu to najlepsze podmioty z kategorii:</p>
                                        <ul>
                                            <li class="bold-big">Produkty</li>
                                            <li class="bold-big">Wypoczynek</li>
                                            <li class="bold-big">Podreperuj zdrowie</li>
                                        </ul>
                                        <div class="sentence-container1" id="secondSentence">
                                            <p class="sentence1">Zaprasza Portal <img src="../../public/photos/Mendel_Helps_bialy1.png" class="logo_life_pod_lupa"/></p>
                                        </div>
                                    </div>
                            </div>
                            <div class="card-panel" id="fifth-slide">
                                <div class="text-inside-slider">
                                <img src="../../public/photos/hand.png" class="hand"/>
                                <h2>WYPOCZYNEK</h2>
                                    <p class="italic-small">Zmęczony codzienną pracą i obowiązkami?</p>
                                    <p class="bold-big">Odwiedź z gwarancją jakości wypoczynku:</p>
                                    <ul>
                                        <li class="bold-big">klimatyczne hotele</li>
                                        <li class="bold-big">wyjątkowe pensjonaty</li>
                                        <li class="bold-big">najlepsze gospodarstwa agroturystyczne</li>
                                    </ul>
                                    <div class="sentence-container12" id="secondSentence">
                                        <p class="sentence1">W bazie naszego Portalu oferty dla Ciebie</p>
                                    </div>
                                    <div class="sentence-container1" id="secondSentence">
                                        <p class="sentence1">Zawsze kiedy potrzebujesz pomocy...<img src="../../public/photos/Mendel_Helps_logo.png"/></p>
                                    </div>
                                </div>
                            </div>
                            <div class="card-panel" id="first-slide4">
                                    <div class="text-inside-slider">
                                        <img src="../../public/photos/hand.png" class="hand"/>
                                        <h2>ZOSTAŃ NASZYM PARTNEREM</h2>
                                        <p class="italic-small">Nasz wymóg to ponadprzeciętna jakość wytwarzanych produktów i świadczonych usług.</p>
                                        <p class="bold-big">Baza Portalu to najlepsze podmioty z kategorii:</p>
                                        <ul>
                                            <li class="bold-big">Produkty</li>
                                            <li class="bold-big">Wypoczynek</li>
                                            <li class="bold-big">Podreperuj zdrowie</li>
                                        </ul>
                                        <div class="sentence-container1" id="secondSentence">
                                            <p class="sentence1">Zaprasza Portal <img src="../../public/photos/Mendel_Helps_bialy1.png" class="logo_life_pod_lupa"/></p>
                                        </div>
                                    </div>
                            </div>
                            <div class="card-panel" id="sixth-slide">
                                <div class="text-inside-slider">
                                <img src="../../public/photos/hand.png" class="hand"/>
                                <h2>PODREPERUJ ZDROWIE</h2>
                                    <p class="italic-small">Sam odpoczynek nie pomógł? Konieczna regeneracja, a może rehabilitacja?</p>
                                    <p class="bold-big">Tylko tutaj znajdziesz:</p>
                                    <ul>
                                        <li class="bold-big">sprawdzonych specjalistów</li>
                                        <li class="bold-big">w najlepszych ośrodkach</li>
                                        <li class="bold-big">z najlepszym zapleczem </li>
                                    </ul>
                                    <div class="sentence-container12" id="secondSentence">
                                        <p class="sentence1">Dbających o prawidłową regenerację i rehabilitację Twojego organizmu</p>
                                    </div>
                                    <div class="sentence-container1" id="secondSentence">
                                        <p class="sentence1">Zawsze, kiedy potrzebujesz pomocy...<img src="../../public/photos/Mendel_Helps_logo.png"/></p>
                                    </div>
                                </div>
                            </div>
                        </Flicking>
                    </div>
                    <div class="header-boxes-container">
                        <div class="two-rows-boxes">
                                <div class="header-box">
                                    <router-link to="/healthyFood">
                                        <div class="header-box-img" :style="{ backgroundImage: 'url(' + backgrounds.img1 + ')' }">
                                            <img :src="backgrounds.img18" class="small-map"/>
                                        </div>
                                        <h2>Zdrowe odżywianie</h2>
                                    </router-link>
                                </div>
                        <div class="header-box">
                                <router-link to="/hotels">
                                    <div class="header-box-img" :style="{ backgroundImage: 'url(' + backgrounds.img2 + ')' }">
                                        <img :src="backgrounds.img14" class="small-map"/>
                                    </div>
                                    <h2>Wypoczynek</h2>
                                </router-link>
                            </div>
                        </div>
                        <div class="two-rows-boxes">
                            <div class="header-box">
                                <router-link to="/bePartner">
                                    <div class="header-box-img" :style="{ backgroundImage: 'url(' + backgrounds.img8 + ')' }">
                                        <img :src="backgrounds.img20" class="small-map"/>
                                    </div>
                                    <h2>Zostań naszym Partnerem</h2>
                                </router-link>
                            </div>
                            <div class="header-box">
                            <router-link to="/products">
                                <div class="header-box-img" :style="{ backgroundImage: 'url(' + backgrounds.img16 + ')' }">
                                    <img :src="backgrounds.img15" class="small-map"/>
                                </div>
                                <h2>Produkty</h2></router-link>
                            </div>
                        </div>
                        <div class="two-rows-boxes">
                            <div class="header-box">
                                <router-link to="/badFood">
                                    <div class="header-box-img" :style="{ backgroundImage: 'url(' + backgrounds.img6 + ')' }">
                                        <img :src="backgrounds.img19" class="small-map"/>
                                    </div>
                                    <h2>Pod lupą</h2>
                                </router-link>
                            </div>
                            <div class="header-box">
                                <router-link to="/rehabilitation">
                                    <div class="header-box-img" :style="{ backgroundImage: 'url(' + backgrounds.img17 + ')' }">
                                        <img :src="backgrounds.img14" class="small-map"/>
                                    </div>
                                    <h2>Podreperuj zdrowie</h2>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div class="healthyFood-inShops">
         <div class="healthy-food">
                <div class="text-container1">
                    <h3>Co jeść?</h3>
                    <p>„Niezależnie od tego, kto był ojcem choroby, zła dieta była na pewno jej matką” .
                    Zapraszamy do wspólnej podróży w kierunku poznania wpływu zdrowego odżywiania na nasze zdrowie,
                    zdrowych produktów i stworzonych z nich dań, które te zdrowie budują.</p>
                    <p><strong>Zajrzyj na nasze porady dotyczące zdrowego odżywiania.</strong></p>
                    <router-link to="/healthyFood/#header-healthy-food"><button class="green-button">Zdrowe odżywianie</button></router-link>
                </div>
                <div class="img-container">
                    <!--<img :src="backgrounds.img5" alt="zdrowe odżywianie"/>-->
                </div>
            </div>
            <div class="healthy-food1">
                <div class="img-container2">
                    <!--<img :src="backgrounds.img6" alt="produkty w sklepie"/>-->
                </div>
                <div class="text-container">
                    <h3>Podziel się swoją opinią</h3>
                    <p>Chcemy wspólnie z Wami, dzięki Waszej pomocy pokazywać i opisywać rzetelny
                    obraz produktów i usług nam oferowanych. Bierzemy pod lupę wszystkich,
                    tak sieci handlowe, jak i sklepy osiedlowe i targowiska. Nie zapominamy o
                    gastronomii, hotelach, ośrodkach wypoczynkowych, rehabilitacyjnych i agroturystyce... </p>
                    <router-link to="/badFood"><button class="green-button1">Pod lupą</button></router-link>
                </div>
            </div>
        </div>
        <div class="searcher-box" id="searcher">
        <div class="searcher-desc-container">
                <div>
                    <p class="thin-row">Ciągle poszerzamy bazę naszych Partnerów oferujących ponadprzeciętnej jakości produkty i usługi </p>
                </div>
                <div>
                    <h3>Wybieramy i wspieramy najlepszych</h3>
                </div>
                <div>
                    <h4>Czego szukasz?</h4>
                </div>
            </div>
            <div class="boxes-container" id="searcher">
                    <div class="box1">
                        <router-link to="/hotels">
                            <div class="box-img" :style="{ backgroundImage: 'url(' + backgrounds.img2 + ')' }">
                                <img :src="backgrounds.img14" class="small-map"/>
                            </div>
                            <div class="desc-box">
                                <h2>Wypoczynek</h2>
                                <p>Sprawdzone hotele, ośrodki wypoczynkowe, pensjonaty, agroturystyka, inne</p>
                            </div>
                        </router-link>
                    </div>
                    <div class="box">
                        <router-link to="/products">
                            <div class="box-img" :style="{ backgroundImage: 'url(' + backgrounds.img16 + ')' }">
                                <img :src="backgrounds.img15" class="small-map"/>
                            </div>
                            <div class="desc-box">
                                <h2>Produkty</h2>
                                <p>Najlepsze produkty od najlepszych rolników, wytwórców i producentów</p>
                            </div>
                        </router-link>
                    </div>
                <div class="box2">
                    <router-link to="/rehabilitation">
                        <div class="box-img" :style="{ backgroundImage: 'url(' + backgrounds.img3 + ')' }">
                                <img :src="backgrounds.img14" class="small-map"/>
                        </div>
                        <div class="desc-box">
                            <h2>Podreperuj zdrowie</h2>
                            <p>Najlepsze w Polsce sanatoria, ośrodki lecznicze i rehabilitacyjne, spa&amp;wellness, inne </p>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    <div v-if="showBalckBackground" class="black-background"></div>
    <div v-if="showCookiePanel" class="cookie">
        <div class="cookie-wrapper">
            <p>Portal Mendel Helps Life wykorzystuje pliki cookies w celu gromadzenia danych statystycznych, emisji reklam, korzystania z narzędzi analitycznych typu Google Analytics, w celach marketingowych, w tym korzystania z narzędzi umożliwiających dostosowanie działań marketingowych oraz zapewnienia prawidłowego funkcjonowania Portalu. W związku z wykorzystaniem plików cookies, są przetwarzane Twoje dane osobowe. Pozostając na Portalu akceptujesz zgodę na przetwarzanie danych osobowych. Więcej informacji, w tym o przysługujących Ci prawach znajdziesz w
            <router-link to="/privatePolicy"><span class="private-policy">POLITYCE PRYWATNOŚCI</span></router-link></p>
            <div class="cookie-buttons">
                <button @click="confirmCookies">Zgoda</button>
                <button @click="returnToPreviousPage">Odmów</button>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
import imgProducts from '../photos/zdrowe_odzywianie1.jpeg';
import imgHotels from '../photos/wypoczynek1.jpeg';
import imgHealth from '../photos/sanatoria1.jpeg';
import imgHealth1 from '../photos/sanatoria2.jpeg';
import imgGarden from '../photos/help.jpeg';
import imgHealthyFood from '../photos/kup_zdrowe_produkty.jpeg';
import imgHealthyFood1 from '../photos/kup_zdrowe_produkty_odbicie.jpeg';
import imgShopProducts from '../photos/co_kupujemy.jpeg';
import imgLogo from '../photos/Mendel_helps_logo.png';
import logo from "../photos/Mendel_Helps_logo.png";
import imgLogoBiale1 from '../photos/Mendel_Helps_bialy1.png';
import logoSentence from '../photos/Mendel_Helps_sentence.png';
import malySygnet from '../photos/Mendel_Helps_maly_sygnet.png';
import hand from '../photos/hand.png';
import smallMap from '../photos/find.png';
import smallMap1 from '../photos/find.png';
import naszePorady from '../photos/nasze_porady1.png';
import poznajFakty from '../photos/poznaj_fakty1.png';
import dowiedzSie from '../photos/dowiedz_sie1.png';
import contact from '../photos/zostan_partnerem.jpeg';
import Flicking from "@egjs/vue3-flicking";
import { AutoPlay } from "@egjs/flicking-plugins";
import axios from "axios";
//import { Arrow } from "@egjs/flicking-plugins";


//const plugins = [new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: false })];

    export default {
        components: {
                Flicking: Flicking
            },
        data() {
            return {
                plugins: [new AutoPlay({ duration: 3000, animationDuration: 2000, direction: "NEXT", stopOnHover: true})],
                plugins1: [new AutoPlay({ duration: 500, animationDuration: 6000, direction: "NEXT", stopOnHover: true})],
                backgrounds: {
                    img1: imgProducts,
                    img2: imgHotels,
                    img3: imgHealth,
                    img4: imgGarden,
                    img5: imgHealthyFood,
                    img6: imgShopProducts,
                    img7: imgLogo,
                    img8: contact,
                    img9: imgLogoBiale1,
                    img10: logo,
                    img11: logoSentence,
                    img12: malySygnet,
                    img13: hand,
                    img14: smallMap,
                    img15: smallMap1,
                    img16: imgHealthyFood1,
                    img17: imgHealth1,
                    img18: naszePorady,
                    img19: poznajFakty,
                    img20: dowiedzSie,
                },
                showCookiePanel: false,
                showBalckBackground: false
            }
        },
    created() {
      this.fetchComapnies();
        const lS = sessionStorage.getItem('first-visit');
      console.log(lS);
      if(lS == "notFirst") {
        this.showCookiePanel = false;
      } else {
        this.showCookiePanel = true;
        this.showBalckBackground = true;
        console.log(this.showCookiePanel);
      }
  },
  methods: {
    fetchComapnies() {
        axios
          .get(`./fetch.php`)
          .then((response) => response)
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.error("Error:", error);
            console.log("server is down!!");
          });
    },
    confirmCookies() {
        sessionStorage.setItem('first-visit', 'notFirst');
        this.showCookiePanel = false;
        this.showBalckBackground = false;
    },
    returnToPreviousPage() {
        this.showBalckBackground = true;
    }
    }
    }
</script>

<style scoped>
@import url("../../node_modules/@egjs/vue3-flicking/dist/flicking.css");
@import "../../node_modules/@egjs/flicking-plugins/dist/arrow.css";
html, body { height: 100%; width: 100%; margin: 0; }
.container-main {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.hight-carusel-conteiner {
    width: 1450px;
    height: 75px;
    margin: 20px auto 0 auto;
    border-radius: 20px;
    align-self: center;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.15) 0px 8px 32px;
}
.header-container2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 240px);
    background-size: cover;
    background-position: center;
    position: relative;
    padding-top: 15px;
    margin-bottom: 80px;
}
.header2 {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 1450px;
    min-height: calc(100vh - 300px);
    text-align: left;
    color: white;
    font-size: 18px;
}
.header-boxes-container {
    width: 50%;
    min-height: calc(100vh - 300px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin-right: -10px;
    }
.two-rows-boxes {
    display: flex;
    width: 100%;
    min-height: calc((100vh - 300px)/3);
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.two-rows-boxes a {
    width: 100%;
}
.header-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    min-height: calc(((100vh - 410px)/3));
    padding: 10px;
    margin: 10px;
    border-radius: 20px;
    transition: 0.3s ease-out;
    box-shadow: rgba(17, 17, 26, 0.04) 0px 4px 16px, rgba(17, 17, 26, 0.25) 0px 8px 32px;
}
.small-map {
    width: 80px;
    position: absolute;
    top: 5px;
    right: 5px;
    transition: 0.3s ease-out;
}
.small-map:hover {
    cursor: pointer;
    transform: scale(1.1);
}
.header-box-img {
    width: 100%;
    height: calc(((100vh - 500px)/3));
    margin: 0;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    position: relative;
}
.header-box h2 {
    color: #303845;
    background-color: white;
    border-radius: 10px;
    font-size: 18px;
    font-weight:600;
    margin: 5px 0 0 0;
    line-height: 25px;
    padding: 0 10px;
}
.header-box:hover {
    cursor: pointer;
    transform: scale(1.1);

}
.sentence-container, .sentence-container1 {
    width:100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sentence-container12 {
    width:100%;
    padding: 0;
    margin: 0;
    color: white;
}
.sentence-container12 p {
    display: inline;
    color: white;
    padding: 0;
    margin: 0;
    line-height: 32px;
}
.sentence-container img, .sentence-container1 img {
    width: 250px;
    margin-left: 10px;
    margin-top: 10px;
}
.sentence-container p{
    display: flex;
    justify-content: center;
    align-items: center;
}
.sentence-container1 .logo_life_pod_lupa {
    display: inline-block;
    margin-bottom:-15px;
}
.sentence-container1 p {
    display: inline;
    justify-content: center;
    align-items: center;
    color: white;
}
.sentence {
    color: #303845;
    font-size: 25px;
    margin:0 auto 0px auto;
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}
.sentence-slider {
    color: white;
    font-size: 23px;
    margin:15 auto 0px auto;
    font-family: "Comfortaa", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}
.sentence span {
    font-size: 50px;
    font-weight: bolder;
}
.header p {
    color: white;
    font-weight: 500;
}
h1 {
    color: #fef24d;
    font-size: 50px;
}
.big-bold {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom:10px;
    font-weight: 600;
}
.normal-big1 {
    font-size: 19px;
    margin-top: 10px;
    font-weight: 500;
}
button {
    padding: 15px 25px;
    border-radius: 20px;
    font-family: "Montserrat", sans-serif;
    background-color: #3084E2;
    border: none;
    font-size: 15px;
    font-weight: 500;
    margin-top: 15px;
}

button:hover {
    background-color: #85BEFF;
    cursor: pointer;
}
.green-button1 {
    width: 200px;
}
.healthyFood-inShops {
    width: 1450px;
}
.healthy-food {
    min-height: 400px;
    width: 1450px;
    display: flex;
    flex-direction: row;
}
.healthy-food1 {
    min-height: 400px;
    margin-top: 80px;
    width: 1450px;
    display: flex;
    flex-direction: row;
}
.img-container {
    margin-left: 20px;
    min-height: 100%;
    background-image: url('../../public/photos/kup_zdrowe_produkty2.jpg');
    background-size: cover;
    background-position: center;
    width:50%;
    border-radius: 20px;
    border: none;
}
.img-container2 {
    min-height: 100%;
    background-image: url('../../public/photos/co_kupujemy2.jpeg');
    background-size: cover;
    background-position: center;
    width:50%;
    border-radius: 20px;
}
.text-container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: right;
    padding-left:30px;
    width: 50%;
}
.text-container p {
    line-height: 25px;
}
.text-container1 {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    padding-right:30px;
    width: 50%;
}
.text-container1 p {
    line-height: 25px;
}
.text-container h3, .text-container1 h3 {
    font-size: 40px;
    margin: 0;
    color: #3084E2;
    font-weight: 600;
}
.searcher-box {
    margin: 100px 0;
    width: 1450px;
}
.searcher-desc-container {
    background-color: #3084E2;
    padding: 30px 200px;
    border-radius: 20px;
    margin-bottom: 50px;
}

.searcher-desc-container h3{
    color: #333333;
    font-size: 34px;
    font-weight: 500;
}
.searcher-desc-container p{
    color: white;
    font-size: 18px;
    line-height: 1.5;
}
.searcher-box .thin-row {
    line-height: 22px;
    font-weight: 500;
}
.searcher-box h4 {
    font-size: 30px;
    color: white;
    font-weight: bold;
}
.boxes-container {
    width: 1450px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center
}
.box, .box1, .box2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 25%;
    padding: 30px;
    border-radius: 20px;
    overflow: hidden;
    transition: 0.3s ease-out;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.25) 0px 8px 32px;
}
.box .box-img{
    background-position: 25%;
}
.box-img {
    height: 350px;
    border-radius: 20px;
    width:100%;
    background-size: cover;
    background-position: center;
    position: relative;
}
.desc-box {
    background-color: white;
    margin: 0 20px 0 20px;
    padding: 0 15px 0 15px;
    color: #5D4DB7;
    border-radius: 20px 20px 0 0;
}
.desc-box h2 {
    font-size: 21px;
    color: #303845;
    font-weight: 500;
}
.desc-box p {
    color: #303845;
    font-size: 15px;
    line-height: 1.3;
}
.insert-product {
    margin: 50px;
}
a {
    text-decoration: none;
}
.box:hover, .box1:hover, .box2:hover {
    transform: scale(1.1);
    z-index: 0;
    cursor: pointer;
}
.panel {
    width: 200px;
    height: 200px;
    color: red;
    z-index: 1000;
}

.flicking-viewport {
  position: relative;
  overflow: hidden;
}

.flicking-viewport.vertical {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.flicking-viewport.vertical > .flicking-camera {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.flicking-viewport.flicking-hidden > .flicking-camera > * {
  visibility: hidden;
}

.flicking-camera {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  z-index: 1;
  will-change: transform;
}

.flicking-camera > * {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.card-panel {
    width: 100%;
    height: calc(100vh - 300px);
    margin: 0;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: justify;
    position: relative;
}
.hand {
    align-self: flex-end;
    width: 35px;
    position: absolute;
    top: 5%;
    right: 5%;
}
#first-slide, #first-slide1, #first-slide2, #first-slide3, #first-slide4 {
    background-image: linear-gradient(to top right, #5D4DB7 , #3084E2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 600px;
}
#first-slide11 {
    /*background-image: linear-gradient(to top right, #5D4DB7 , #3084E2);*/
    background-color: white;
    color: #3084E2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 600px;
}
#first-slide11 .sentence11 {
    margin-top: 70px;
    color: #1F1F1F;
    text-align: center;
    font-family: "Comfortaa", sans-serif;
    font-size: 25px;
    font-weight: 600;
}
#first-slide11 .sentence12 {
    color: white;
    font-family: "Comfortaa", sans-serif;
    font-size: 45px;
    font-weight: 900;
}
#first-slide11 .sygnet-slider {
    width: 170px;
    margin-bottom: 20px;
}
.sentence12 {
    display:none;
}
#secondSentence1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
/*#first-slide img, #first-slide1 img, #first-slide2 img, #first-slide3 img, #first-slide4 img {
    width: 200px;
}*/
.logo {
    width:80px;
    bottom: 10%;
    left: 10%;
    align-self: center;
}
.carusel-container {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    overflow: hidden;
    height: calc(100vh - 310px);
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.25) 0px 8px 32px;
}
.text-inside-slider {
    margin: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    line-height: 23px;
}
.text-inside-slider ul {
    margin: 0 0 20px 0;
    padding: 0;
    list-style-position: inside;
}
.text-inside-slider h2 {
    align-self: flex-start;
}
.italic-small {
    font-style: italic;
    font-size: 17px;
    line-height: 20px;
    margin: 10px 0;
    padding: 0;
}
.normal-big {
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
}
.bold-big {
    font-size: 20px;
    font-weight: 600;
    margin: 5px;
}
#second-slide {
    background-image: url('../../public/photos/zdrowe_odzywianie1.jpeg');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 600px;
}
#third-slide {
    background-image: url('../../public/photos/kup_zdrowe_produkty.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 600px;
}
#fourth-slide {
    background-image: url('../../public/photos/co_kupujemy1.jpeg');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 600px;
}
#fifth-slide {
    background-image: url('../../public/photos/wypoczynek12.jpeg');
    background-color: #303845;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 600px;
}
#sixth-slide {
    background-image: url('../../public/photos/sanatoria1.jpeg');
    background-color: #303845;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 600px;
}
.cookie {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    margin: 0;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    bottom:0;
    font-size: 14px;
    border: 2px solid #3084E2;
    border-radius: 20px;
    z-index: 10000;
    background-color: white;
}
.cookie p {
    float: left;
    text-align: justify;
    margin: 0 auto;
}
.cookie div {
    margin: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center
}
.cookie-wrapper {
    width: 1450px;
    margin: 0 auto;
}
.cookie .cookie-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cookie button {
    padding: 8px 25px;
    margin: 5px;
    width: 120px;
}
.private-policy {
    font-weight: 600;
    color: black;
}
.black-background {
    position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

@media only screen and (max-height: 850px) {
.header-container2 {
    min-height: 600px;
}
.header2 {
    min-height: 600px;
}
.header-boxes-container {
    min-height: 600px;
}
.two-rows-boxes {
    min-height: 200px;
}
.header-box {
    min-height: 160px;
}
.header-box-img {
    height: 140px;
}
.card-panel {
    width: 100%;
    height: 610px;
    margin: 0;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: justify;
}
.carusel-container {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    overflow: hidden;
    height: 610px;
}
}
@media only screen and (max-width: 1450px) {
.hight-carusel-conteiner {
    width: 1150px;
}
  .header2 {
    width: 1150px;
    font-size: 17px;
}
.healthyFood-inShops {
    width: 1150px;
}
.healthy-food {
    width: 1150px;
}
.healthy-food1 {
    width: 1150px;
}
.searcher-box {
    width: 1150px;
}
.searcher-desc-container {
    padding: 30px 100px;
}
.boxes-container {
    width: 1150px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center
}
}
@media only screen and (max-width: 1200px) {
.hight-carusel-conteiner {
    width: 1000px;
}
  .header2 {
    width: 1000px;
    font-size: 17px;
}
.small-map {
    width: 60px;
}
.healthyFood-inShops {
    width: 1000px;
}
.healthy-food {
    width: 1000px;
}
.healthy-food1 {
    width: 1000px;
}
.searcher-box {
    width: 1000px;
}
.searcher-desc-container {
    padding: 20px 70px;
}
.boxes-container {
    width: 1000px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center
}
}
@media only screen and (max-width: 1000px) {
.hight-carusel-conteiner {
    width: 800px;
}
  .header2 {
    width: 800px;
    font-size: 17px;
}
.healthyFood-inShops {
    width: 800px;
}
.healthy-food {
    width: 800px;
}
.healthy-food1 {
    width: 800px;
}
.small-map {
    width: 60px;
}
.searcher-box {
    width: 800px;
}
.searcher-desc-container {
    padding: 20px 70px;
}
.boxes-container {
    width: 800px;
}
.header-box {
    padding: 5px;
    margin: 5px;
}
.header-box h2 {
    font-size: 15px;
    margin: 3px 0 0 0;
    line-height: 18px;
    padding: 0 5px;
}
}
@media only screen and (max-width: 800px) {
.hight-carusel-conteiner {
    height: 50px;
    margin-top: 10px;
    border-radius: 20px;
    font-size: 15px;
    width: 600px;
}
  .header2 {
    width: 600px;
    font-size: 17px;
}
.healthyFood-inShops {
    width: 600px;
}
.healthy-food {
    width: 600px;
}
.healthy-food1 {
    width: 600px;
}
.searcher-box {
    width: 600px;
}
.searcher-desc-container {
    padding: 20px 70px;
}
.boxes-container {
    width: 600px;
}
.header-box {
    padding: 5px;
    margin: 5px;
}
.header-box h2 {
    font-size: 15px;
    margin: 3px 0 0 0;
    line-height: 18px;
    padding: 0 5px;
}
.sentence-container {
    padding: 5px;
}
.sentence-container img {
    width: 180px;
    margin-left: 5px;
    margin-top: 5px;
}
.sentence-container p {
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sentence-container1 p{
    padding: 5px;
    font-size: 18px;
}
.sentence-container1 img {
    width: 150px;
}
.sentence-container12 p{
    font-size: 17px;
    line-height: 18px;
    margin: 0;
    padding:0;
}
.text-inside-slider {
    line-height: 23px;
}
.text-inside-slider h2 {
    align-self: flex-start;
    font-size: 20px;
}
.italic-small {
    font-style: italic;
    font-size: 15px;
    line-height: 20px;
}
.normal-big {
    font-size: 20px;
    line-height: 25px;
}
.bold-big {
    font-size: 18px;
    font-weight: 600;
}
.text-container {
    padding-left:10px;
    width: 50%;
}
.text-container p {
    line-height: 20px;
    font-size: 15px;
}
.text-container1 {
    padding-right:10px;
    width: 50%;
}
.text-container1 p {
    line-height: 20px;
    font-size: 15px;
}
.text-container h3, .text-container1 h3 {
    font-size: 30px;
    margin: 0;
    color: #3084E2;
    font-weight: 600;
}
.searcher-desc-container {
    padding: 10px 50px;
    border-radius: 20px;
    margin-bottom: 30px;
}

.searcher-desc-container h3{
    font-size: 24px;
}
.searcher-desc-container p{
    color: white;
    font-size: 16px;
    line-height: 1.5;
}
.searcher-box .thin-row {
    line-height: 22px;
}
.searcher-box h4 {
    font-size: 27px;
}
.box, .box1, .box2  {
    align-items: center;
    width: 28%;
    padding: 10px;
    border-radius: 20px;
}
.box-img {
    height: 220px;
    border-radius: 20px;
    width:100%;
}
.desc-box {
    background-color: white;
    margin: 0 10px 0 10px;
    padding: 0 8px 0 8px;
    color: #5D4DB7;
    border-radius: 20px 20px 0 0;
}
.desc-box h2 {
    font-size: 18px;
    color: #303845;
    font-weight: 500;
}
.desc-box p {
    color: #303845;
    font-size: 14px;
    line-height: 1.3;
}
.card-panel {
    position: relative;
}
.hand {
    align-self: flex-end;
    width: 30px;
    position: absolute;
    top: 5%;
    right: 5%;
}
#secondSentence1 .sentence11 {
    font-size: 20px;
}
}
@media only screen and (max-width: 600px) {
.container-main {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
}
.hight-carusel-conteiner {
    height: 40px;
    margin: 10px 5px 0 5px;
    border-radius: 20px;
    font-size: 15px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}
  .header2 {
    width: 100%;
    box-sizing: border-box;
    font-size: 17px;
}
.header-container2 {
    width:100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 10px;
    margin-bottom: 30px;
}
.card-panel {
    width: 100%;
    box-sizing: border-box;
    height: 200px;
    margin: 0;
    border-radius: 20px;
    position: relative;
}
.hand {
    align-self: flex-end;
    width: 30px;
    position: absolute;
    top:27%;
    right: 5%;
}
.header2 {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 200px;
    font-size: 18px;
}
.two-rows-boxes {
    width: 100%;
    box-sizing: border-box;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    margin: 0;
    padding: 0;
}
.carusel-container {
    width: 100%;
    box-sizing: border-box;
    height: 300px;
    margin: 5px 5px 0 5px;
    padding: 0px;
    border-radius:20px;
    overflow: hidden;
}
.logo {
    width:50px;
    bottom: 10%;
    left: 10%;
}
.healthyFood-inShops {
    width: 100%;
}
.healthy-food {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin:0;
}
.img-container, .img-container2 {
    width: 100%;
    height: 150px;
    margin: 0;
    box-sizing: border-box;
}
.healthy-food1 {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    margin: 30px 0 0 0;
    padding: 0;
}
.searcher-box {
    width: 100%;
    margin: 20px auto;
}
.searcher-desc-container {
    padding: 20px 30px;
}
.header-boxes-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.boxes-container {
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
}
.header-box {
    width: 100%;
    min-height: 180px;
    padding: 5px;
    margin: 3px;
}
.header-box:hover {
    transform: none;
}
.header-box h2 {
    font-size: 14px;
    margin: 3px 0 0 0;
    line-height: 17px;
    padding: 0 5px;
}
.sentence-container {
    width:100%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sentence-container img {
    width: 150px;
    margin-left: 5px;
    margin-top: 5px;
}
.sentence-container p {
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 16px;
}
.sentence-container1 p{
    margin: 0;
    font-size: 16px;
    line-height: 18px;
}
.sentence-container1 .logo_life_pod_lupa {
    display: inline-block;
    margin-bottom:-10px;
}
.text-inside-slider {
    line-height: 18px;
}
.text-inside-slider h2 {
    align-self: flex-start;
    font-size: 18px;
}
.text-inside-slider ul {
    margin-bottom: 0;
}
.italic-small {
    font-size: 14px;
    line-height: 17px;
    margin:0 0 5px 0;
}
.normal-big {
    font-size: 16px;
    line-height: 19px;
}
.bold-big {
    margin-top: 0;
    margin-left: 0;
    font-size: 15px;
    font-weight: 500;
}
.text-container {
    align-items: flex-start;
    margin:0 0 20px 0;
    padding: 0;
    width: 100%;
    box-sizing: border-box;
}
.text-container p {
    text-align: justify;
    margin: 0;
    line-height: 19px;
    font-size: 15px;
}
.text-container1 {
    padding:0px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
}
.text-container1 p {
    margin: 0;
    line-height: 19px;
    font-size: 15px;
    text-align: justify;
}
.text-container h3, .text-container1 h3 {
    font-size: 25px;
    margin: 0;
    color: #3084E2;
    font-weight: 600;
}
.text-container h3 {
    align-self: left;
}
.img-container {
    width: 100%;
    box-sizing: border-box;
    padding: 0px;
    margin: 0;
}
.green-button {
    padding: 10px 15px;
}
.searcher-desc-container {
    padding: 10px 20px;
    border-radius: 20px;
    margin-bottom: 20px;
    margin-top: 0;
}

.searcher-desc-container h3{
    font-size: 24px;
}
.searcher-desc-container p{
    color: white;
    font-size: 16px;
    line-height: 1.5;
}
.searcher-box .thin-row {
    line-height: 22px;
}
.searcher-box h4 {
    font-size: 27px;
}
.box, .box1, .box2  {
    align-items: center;
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    margin: 5px 0;
    box-sizing: border-box;
}
.box:hover, .box1:hover, .box2:hover {
    transform: none;
}
.box-img {
    height: 150px;
    border-radius: 20px;
    width:100%;
}
.desc-box {
    background-color: white;
    margin: 0 5px 0 5px;
    padding: 0 5px 0 5px;
    color: #5D4DB7;
    border-radius: 20px 20px 0 0;
}
.desc-box h2 {
    font-size: 17px;
    color: #303845;
    font-weight: 500;
    margin:5px;
}
.desc-box p {
    color: #303845;
    font-size: 14px;
    line-height: 1.3;
    margin:0;
}
#secondSentence1 .sentence11 {
    font-size: 20px;
    text-align: center;
}
#first-slide11 .sentence11 {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 600;
}
#first-slide11 .sygnet-slider {
    width:100px;
    margin-bottom:0;
}
}
@media only screen and (max-width: 450px) {
 .sentence-container p {
    font-size: 13px;
    line-height: 14px;
}
 .sentence-container12 p {
    font-size: 15px;
 }
.card-panel {
    height: 360px;
    margin: 0;
}
.text-inside-slider {
    height: auto;
}
.text-inside-slider h2 {
    margin: 5px 0;
}
.big-bold {
    margin: 3px 0;
    font-size: 15px;
    padding: 0;
}
.italic-small {
    font-size: 13px;
    line-height: 14px;
}
.text-container p {
    text-align: justify;
    margin: 0;
    line-height: 15px;
    font-size: 14px;
}
.text-container1 {
    padding:0px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
}
.text-container1 p {
    margin: 0;
    line-height: 19px;
    font-size: 15px;
    text-align: justify;
}
}
</style>