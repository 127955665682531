import { createRouter, createWebHistory } from 'vue-router';
//import VueRouter from 'vue-router'

import TheProducts from './components/TheProducts.vue';
import MainPage from './components/MainPage.vue';
import AddCompany from './components/AddCompany.vue';
import HealthyFood from './components/HealthyFood.vue';
import AboutUs from './components/AboutUs.vue';
import TheContact from './components/TheContact.vue';
import TheHotels from './components/TheHotels.vue';
import TheRehabilitation from './components/TheRehabilitation.vue';
//import WBudowie from './components/WBudowie.vue';
import PrivatePolicy from './components/PrivatePolicy.vue';
import TheStatute from './components/TheStatute.vue';
import BadFood from './components/BadFood.vue';
import BePartner from './components/BePartner.vue';
import BePartnerProuctsForm from './components/BepartnerProductsform.vue';
import BePartnerHotelsForm from './components/BePartnerHotelsForm.vue';
import BeRehabilitationForm from './components/BePartnerRehabilitation.vue';
import TheCertificate from './components/TheCertificate.vue';
//import TheCarusel from './components/TheCarusel.vue';

export const router = createRouter({
    BASE_URL: 'https://mendelhelps.pl',
    //BASE_URL: 'http://localhost:8081',
    mode: 'hash',
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
      },
    routes: [
        {path: '/', name: 'MainPage', component: MainPage},
    //    {path: '/main', name: 'MainPage', component: MainPage},
        {path: '/products', name: 'TheProducts', component: TheProducts},
        {path: '/aboutUs', name: 'AboutUs', component: AboutUs},
        {path: '/addCompany', name: 'AddCompany', component: AddCompany},
        {path: '/healthyFood', name: 'HealthyFood', component: HealthyFood},
        {path: '/contact', name: 'TheContact', component: TheContact},
        {path: '/hotels', name: 'TheHotels', component: TheHotels},
        {path: '/rehabilitation', name: 'TheRehabilitation', component: TheRehabilitation},
        {path: '/privatePolicy', name: 'PrivatePolicy', component: PrivatePolicy},
        {path: '/statute', name: 'TheStatute', component: TheStatute},
        {path: '/badFood', name: 'BadFood', component: BadFood},
        {path: '/certyfikat', name: 'Certyfikat', component: TheCertificate},
        {path: '/bePartner', name: 'BePartner', component: BePartner},
        {path: '/bePartnerProductsForm', name: 'BePartnerProductsForm', component: BePartnerProuctsForm},
        {path: '/bePartnerHotelsForm', name: 'BePartnerHotelsForm', component: BePartnerHotelsForm},
        {path: '/BeRehabilitationForm', name: 'BeRehabilitationForm', component: BeRehabilitationForm},
        //{path: '/theCarusel', name: 'TheCarusel', component: TheCarusel},
    ]
});

//export default {router};